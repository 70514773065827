import {FC, Fragment, useCallback, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';

import {EmailString} from '@shared/dynamo_model';
import {useTheme} from '@shared/frontends/theme_context';

import {sharedApiCall} from '@shared-frontend/api';
import {Button, NavLink, UnthemedNavLink} from '@shared-frontend/components/core/button';
import {Input} from '@shared-frontend/components/core/input_v2';
import {Spacing} from '@shared-frontend/components/core/spacing';
import {notifyError} from '@shared-frontend/lib/notification';
import {setSession} from '@shared-frontend/lib/session_store';
import {optionalPx} from '@shared-frontend/lib/styled_utils';
import {useParams} from '@shared-frontend/lib/use_typed_params';

export const ResetPassword: FC = () => {
  const {id} = useParams<{id: string}>();
  const {
    main: {api, textColor, logo},
    auth: {postLoginPage},
  } = useTheme();
  const navigate = useNavigate();

  const [email, setEmail] = useState<string | undefined>();
  const [password, setPassword] = useState<string | undefined>();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handlePasswordResetSubmit = useCallback(() => {
    setIsSubmitting(true);
    if (id === undefined) {
      navigate('/');
      return;
    }
    if (email === undefined) {
      notifyError('Veuillez entrer une adresse email');
      return;
    }
    if (password === undefined) {
      notifyError('Veuillez entrer un mot de passe');
      return;
    }
    sharedApiCall(api, '/reset-password', {
      id,
      email: email as EmailString,
      password,
    })
      .then(data => {
        setIsSubmitting(false);
        if (data.success) {
          setSession(data.session);
          navigate(postLoginPage);
        } else {
          notifyError('Échec de la réinitialisation du mot de passe');
        }
      })
      .catch(err => {
        setIsSubmitting(false);
        notifyError(err);
      });
  }, [id, email, password, api, navigate, postLoginPage]);

  return (
    <Form onSubmit={handlePasswordResetSubmit}>
      <LogoNavLink to="/">{logo}</LogoNavLink>
      <Spacing height={24} />
      <Fragment>
        <Title>Mot de passe oublié ?</Title>
        <Subtitle>Modifiez votre mot de passe</Subtitle>
        <Separator $color={textColor} $top={22} $bottom={18} />
        <FormInput>
          <Input
            width="100%"
            type="email"
            value={email}
            syncState={setEmail}
            placeholder="jean@exemple.com"
            label="ADRESSE EMAIL"
            autoComplete="email"
            autoFocus
          />
        </FormInput>
        <Spacing height={12} />
        <FormInput>
          <Input
            width="100%"
            type="password"
            value={password}
            syncState={setPassword}
            label="NOUVEAU MOT DE PASSE"
            autoComplete="new-password"
          />
        </FormInput>
        <Spacing height={28} />
        <LegalText>
          En continuant, vous reconnaissez avoir lu les{' '}
          <NavLink to="/terms">mentions légales</NavLink>
        </LegalText>
        <Spacing height={28} />
        <Button expand submit disabled={isSubmitting}>
          Modifier le mot de passe
        </Button>
      </Fragment>
    </Form>
  );
};

ResetPassword.displayName = 'Login';

const Form = styled.form`
  width: 95vw;
  max-width: 360px;
  height: 100%;
  margin: auto;
  padding: 32px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1.5;
`;

const LogoNavLink = styled(UnthemedNavLink)`
  height: 64px;
  & > * {
    width: 100%;
    height: 100%;
  }
`;

const Title = styled.h3`
  font-weight: 700;
  font-size: 160%;
`;

const Subtitle = styled.p`
  opacity: 0.6;
  font-weight: 400;
`;

const FormInput = styled.div`
  width: 100%;
  text-align: left;
`;

const Separator = styled.div<{$color: string; $top?: number; $bottom?: number}>`
  height: 1px;
  width: 100%;
  background-color: ${p => p.$color}20;
  ${p => optionalPx('margin-top', p.$top)}
  ${p => optionalPx('margin-bottom', p.$bottom)}
`;

const LegalText = styled.div`
  width: 100%;
  text-align: left;
  font-size: 80%;
`;
