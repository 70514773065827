import {FC, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';

import {NOT_FOUND} from '@shared/api/status_codes';
import {useSsrContext} from '@shared/frontends/use_ssr_context';

import {Button, NavLink} from '@shared-frontend/components/core/button';
import {ALL_WIDTH, Image} from '@shared-frontend/components/core/image';

import {Colors, FontWeight} from '@src/components/core/theme_base';
import logo from '@src/images/logo.png';

export const NotFoundPage: FC = () => {
  useSsrContext().setStatusCode(NOT_FOUND);

  const navigate = useNavigate();

  const handleContinue = useCallback(() => {
    navigate('/');
  }, [navigate]);

  return (
    <Container>
      <Form>
        <Line>
          <NavLink to="/">
            <Image
              srcAndSizes={{
                [ALL_WIDTH]: {srcData: logo, width: '100vw'},
              }}
              alt="Logo Hoobiiz"
              height={70}
            />
          </NavLink>
        </Line>
        <Line>
          <Title>Page introuvable</Title>
          <Subtitle>Cette page n'existe plus.</Subtitle>
        </Line>
        <StyledButton type="button" onClick={handleContinue}>
          Retour à l'accueil
        </StyledButton>
      </Form>
    </Container>
  );
};

NotFoundPage.displayName = 'NotFoundPage';

const Subtitle = styled.p`
  color: ${Colors.Grey};
  margin-bottom: 16px;
  font-weight: ${FontWeight.Regular400};
  line-height: 22px;
`;

const Line = styled.div`
  margin-bottom: 24px;
`;

const Title = styled.h3`
  font-weight: ${FontWeight.Bold700};
  font-size: 25px;
  line-height: 25px;
  margin-bottom: 4px;
`;

const Form = styled.div`
  text-align: center;
  width: 360px;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
`;

const StyledButton = styled(Button)`
  width: 100%;
  border-radius: 6px;
  font-size: 13px;
`;

const Container = styled.div`
  align-self: center;
  margin-top: -32px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;
