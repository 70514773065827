import {Dispatch, FC, useCallback} from 'react';
import styled from 'styled-components';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {SearchApiQueryType} from '@shared/api/definitions/search_api';
import {useSsrContext} from '@shared/frontends/use_ssr_context';
import {FullItem} from '@shared/model/search_tables';

import {apiCall} from '@shared-frontend/api';
import {Autocomplete} from '@shared-frontend/components/core/autocomplete';
import {NavLink} from '@shared-frontend/components/core/button';
import {Image} from '@shared-frontend/components/core/image';
import {EmptyFragment} from '@shared-frontend/lib/react';

import {adminInputTheme} from '@src/components/core/theme';
import {mediaSrcData} from '@src/lib/hoobiiz_media';

interface Category1InputProps {
  category1?: FullItem<'HoobiizCat1'>;
  label?: string;
  syncState?: Dispatch<FullItem<'HoobiizCat1'> | undefined>;
  autoFocus?: boolean;
}

export const Category1Input: FC<Category1InputProps> = props => {
  const {category1, label: labelStr = 'CATÉGORIE PARENTE', syncState, autoFocus} = props;
  const {host} = useSsrContext();

  const category1Lookup = useCallback(
    async (inputText: string) =>
      apiCall(HoobiizApi, '/admin/search/query', {
        table: 'HoobiizCat1',
        match: {matcher: 'name', value: inputText},
        limit: 10,
        mode: 'full',
        permissions: [],
      }).then(res => {
        const {items} = res as SearchApiQueryType<'HoobiizCat1', 'full'>['res'];
        return items.map(i => i.item);
      }),
    []
  );

  const lookupInitial = useCallback(
    async () =>
      apiCall(HoobiizApi, '/admin/search/query', {
        table: 'HoobiizCat1',
        limit: 10,
        mode: 'full',
        sort: {name: 'order', params: undefined},
        permissions: [],
      }).then(res => {
        const {items} = res as SearchApiQueryType<'HoobiizCat1', 'full'>['res'];
        return items.map(i => i.item);
      }),
    []
  );

  const resultToString = useCallback((res: FullItem<'HoobiizCat1'>) => res.name, []);
  const resultToKey = useCallback((res: FullItem<'HoobiizCat1'>) => res.id, []);
  const resultToElement = useCallback(
    (res: FullItem<'HoobiizCat1'>, highlighted: boolean) => {
      const {media, mediaId} = res;
      return (
        <Result $highlighted={highlighted}>
          {media ? (
            <StyledImage
              alt={''}
              srcAndSizes={{srcData: mediaSrcData(host, {media, id: mediaId}), width: 48}}
              rawUrls
              width={48}
              height={48}
            />
          ) : (
            EmptyFragment
          )}
          <ResultVendor>
            <ResultMainText>{res.name}</ResultMainText>
          </ResultVendor>
        </Result>
      );
    },
    [host]
  );

  const label =
    category1 === undefined ? (
      labelStr
    ) : (
      <LabelWithLink>
        {labelStr}
        <NavLink to={`/admin/cat1/${category1.id}`}>Voir</NavLink>
      </LabelWithLink>
    );

  return (
    <Autocomplete
      lookupItem={category1Lookup}
      lookupInitial={lookupInitial}
      item={category1}
      syncState={syncState}
      itemToInputString={resultToString}
      itemToKey={resultToKey}
      label={label}
      overrides={adminInputTheme}
      itemElement={resultToElement}
      autoFocus={autoFocus}
    />
  );
};
Category1Input.displayName = 'Category1Input';

const Result = styled.div<{$highlighted?: boolean}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;
  padding: 14px;
  cursor: pointer;
  ${p => (p.$highlighted ? `background-color: #f6f6f6;` : `background-color: #ffffff;`)};
  &:hover {
    background-color: #f6f6f6;
  }
`;

const ResultVendor = styled.div`
  display: flex;
  flex-direction: column;
`;

const ResultMainText = styled.span`
  line-height: 20px;
  font-size: 16px;
  font-weight: 500;
`;

const StyledImage = styled(Image)`
  border-radius: 8px;
  box-shadow: 0 0 7px -1px #0000002e;
`;

const LabelWithLink = styled.div`
  display: flex;
  gap: 8px;
`;
