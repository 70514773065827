import {FC, ReactNode} from 'react';

import {FrontendUserAuthMethodType} from '@shared/dynamo_model';
import {IS_PRODUCTION_ENV} from '@shared/env_constants';
import {FrontendName} from '@shared/frontends/frontend_constant';
import {useTheme} from '@shared/frontends/theme_context';
import {FrontendUserDataType} from '@shared/model/auth/user';

import {ForgotPassword} from '@shared-frontend/components/auth/forgot_password';
import {FrontendUserDataFormProps} from '@shared-frontend/components/auth/frontend_user_data_form';
import {Link} from '@shared-frontend/components/auth/link';
import {Login} from '@shared-frontend/components/auth/login';
import {Logout} from '@shared-frontend/components/auth/logout';
import {Register} from '@shared-frontend/components/auth/register';
import {RegisterInvite} from '@shared-frontend/components/auth/register_invite';
import {RegisterLink} from '@shared-frontend/components/auth/register_link';
import {ResetPassword} from '@shared-frontend/components/auth/reset_password';
import {RouteOpts, useRoute} from '@shared-frontend/components/core/use_route';
import {FrontendUi} from '@shared-frontend/components/frontend_ui';
import {ComponentClass, useComponentClass} from '@shared-frontend/lib/react';

interface SharedRoutesOpts<Name extends FrontendName> {
  userDataForm: FrontendUserDataType<Name> extends undefined
    ? undefined
    : FC<FrontendUserDataFormProps<Exclude<FrontendUserDataType<Name>, undefined>>>;
  DisabledComponent: ComponentClass;
}

export function useSharedRoutes<Name extends FrontendName>(
  opts: RouteOpts,
  sharedRoutesOpts: SharedRoutesOpts<Name>
): ReactNode[] {
  const {
    auth: {userAuthTypes},
  } = useTheme();
  const {userDataForm, DisabledComponent} = sharedRoutesOpts;
  const hasPasswordAuth = userAuthTypes.includes(FrontendUserAuthMethodType.Password);

  return [
    useRoute('/login', Login, opts),
    useRoute('/register-invite', RegisterInvite, opts),
    useRoute('/forgot-password', ForgotPassword, opts),
    useRoute('/reset-password/:id', ResetPassword, opts),
    useRoute('/logout', Logout, opts),
    useRoute('/link/:id', Link, opts),
    useRoute('/ui', FrontendUi, {
      ...opts,
      isDisabledHandler: () => (IS_PRODUCTION_ENV ? DisabledComponent : undefined),
    }),
    useRoute('/register', useComponentClass(<Register UserDataForm={userDataForm} />), {
      ...opts,
      isDisabledHandler: () => (hasPasswordAuth ? undefined : DisabledComponent),
    }),
    useRoute('/register-link/:id', RegisterLink, {
      ...opts,
      isDisabledHandler: () => (hasPasswordAuth ? undefined : DisabledComponent),
    }),
  ];
}
