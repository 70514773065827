import {Fragment, useEffect, useRef, useState} from 'react';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {SearchApiGetType} from '@shared/api/definitions/search_api';
import {HoobiizExpertTicketInfo} from '@shared/dynamo_model';
import {joinWithLastJoiner} from '@shared/lib/string_utils';
import {FullItem} from '@shared/model/search_tables';

import {apiCall} from '@shared-frontend/api';
import {Input} from '@shared-frontend/components/core/input_v2';
import {notifyError} from '@shared-frontend/lib/notification';
import {Custom, EmptyFragment, NULL_REF} from '@shared-frontend/lib/react';

import {ExpertTicketProductInput} from '@src/components/admin/expert_ticket/expert_ticket_product_input';
import {FormBlock, FormBlockFull, FormFlex} from '@src/components/admin/form/form_fragments';
import {HoobiizWarnings} from '@src/components/admin/form/hoobiiz_warnings';
import {adminInputTheme} from '@src/components/core/theme';

export type MaybeExpertTicket = HoobiizExpertTicketInfo | Pick<HoobiizExpertTicketInfo, 'id'>;

export function ensureExpertTicketInfo(
  tickets: MaybeExpertTicket[]
): {success: false; err: string} | {success: true; tickets: HoobiizExpertTicketInfo[]} {
  const ticketInfos: HoobiizExpertTicketInfo[] = [];
  const incompleteIndexes: number[] = [];
  for (const [index, ticket] of tickets.entries()) {
    if (!('label' in ticket)) {
      incompleteIndexes.push(index);
    }
  }
  if (incompleteIndexes.length > 0) {
    const plural = incompleteIndexes.length > 1 ? 's' : '';
    return {
      success: false,
      err: `Ticket${plural} ${joinWithLastJoiner(
        incompleteIndexes.map(i => `#${i + 1}`),
        {sep: ', ', lastSep: ' et '}
      )} incomplet${plural}`,
    };
  }
  return {success: true, tickets: ticketInfos};
}

interface HoobiizExpertTicketInfoFormProps {
  initialData: MaybeExpertTicket;
  onChange?: (newVal: MaybeExpertTicket, el: HTMLDivElement) => void;
}

export const HoobiizExpertTicketInfoForm: Custom<
  HoobiizExpertTicketInfoFormProps,
  'div',
  'onChange'
> = props => {
  const ref = useRef<HTMLDivElement>(NULL_REF);
  const {initialData, onChange, ...rest} = props;

  const [label, setLabel] = useState('label' in initialData ? initialData.label : '');
  const [description, setDescription] = useState(
    'description' in initialData ? initialData.description ?? '' : ''
  );
  const [product, setProduct] = useState<FullItem<'HoobiizExpertTicketProduct'> | undefined>();
  const [productFetched, setProductFetched] = useState(false);
  const [isComplete, setIsComplete] = useState(false);

  useEffect(() => {
    if (!productFetched && 'productId' in initialData && product === undefined) {
      apiCall(HoobiizApi, '/admin/search/get', {
        table: 'HoobiizExpertTicketProduct',
        mode: 'full',
        id: initialData.productId,
        permissions: [],
      })
        .then(res => {
          const {item} = res as SearchApiGetType<'HoobiizExpertTicketProduct', 'full'>['res'];
          setProduct(item);
          setProductFetched(true);
        })
        .catch(notifyError);
    }
  }, [initialData, product, productFetched]);

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    if (label.length === 0 || product === undefined) {
      setIsComplete(false);
      onChange?.({id: initialData.id}, ref.current);
      return;
    }
    setIsComplete(true);
    onChange?.(
      {
        id: initialData.id,
        label,
        description,
        productId: product.id,
      },
      ref.current
    );
  }, [description, initialData.id, label, onChange, product]);

  return (
    <Fragment>
      <FormFlex ref={ref} $align="flex-end" {...rest}>
        <FormBlock>
          <Input
            width="100%"
            value={label}
            syncState={setLabel}
            placeholder={'label' in initialData ? initialData.label : ''}
            label="LIBELLÉ"
            overrides={adminInputTheme}
          />
        </FormBlock>
        <FormBlock>
          <Input
            width="100%"
            value={description}
            syncState={setDescription}
            placeholder={'description' in initialData ? initialData.description : ''}
            label="INFOBULLE"
            overrides={adminInputTheme}
          />
        </FormBlock>
        <FormBlock>
          <ExpertTicketProductInput product={product} syncState={setProduct} />
        </FormBlock>
      </FormFlex>
      {isComplete ? (
        EmptyFragment
      ) : (
        <FormBlockFull>
          <HoobiizWarnings warnings={['Ticket incomplet ou invalide']} />
        </FormBlockFull>
      )}
    </Fragment>
  );
};
HoobiizExpertTicketInfoForm.displayName = 'HoobiizExpertTicketInfoForm';
