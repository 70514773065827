import {FC, useEffect, useState} from 'react';

import {HoobiizActivityAddressManual, HoobiizActivityAddressType} from '@shared/dynamo_model';
import {uidUnsafe} from '@shared/lib/rand';

import {
  HoobiizVendorLocationWithFrontendId,
  VendorLocationForm,
} from '@src/components/admin/vendor/vendor_location_form';
import {vendorLocationWithFrontendIdToVendorLocation} from '@src/lib/hoobiiz_vendor_location';

interface ActivityAddressManualFormProps {
  initialData?: HoobiizActivityAddressManual;
  onChange: (newVal: HoobiizActivityAddressManual | undefined) => void;
}

export const ActivityAddressManualForm: FC<ActivityAddressManualFormProps> = props => {
  const {initialData, onChange} = props;

  const [location, setLocation] = useState<HoobiizVendorLocationWithFrontendId>({
    id: uidUnsafe(),
    ...(initialData?.location ?? {address: initialData?.address}),
  });

  useEffect(() => {
    try {
      const address = vendorLocationWithFrontendIdToVendorLocation(location);
      onChange({type: HoobiizActivityAddressType.Manual, location: address});
    } catch {
      onChange(undefined);
    }
  }, [location, onChange]);

  return <VendorLocationForm location={location} onChange={setLocation} />;
};
ActivityAddressManualForm.displayName = 'ActivityAddressManualForm';
