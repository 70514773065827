import {FC, useEffect, useMemo, useState} from 'react';

import {
  HoobiizOpeningHours,
  HoobiizStockMode,
  HoobiizStockWeeklyTemplateId,
  HoobiizTicketInfo,
  HoobiizTicketInfoOption,
  HoobiizTimeOfDay,
  HoobiizTimePeriod,
  HoobiizWeekday,
} from '@shared/dynamo_model';
import {max, min} from '@shared/lib/array_utils';
import {compareTimeOfDay} from '@shared/lib/hoobiiz/compare_hoobiiz_model';
import {hoobiizTicketInfoId} from '@shared/lib/hoobiiz/hoobiiz_ids';
import {initialPeriod} from '@shared/lib/hoobiiz/period';
import {entries, values} from '@shared/lib/map_utils';
import {iterStringEnum, removeUndefined} from '@shared/lib/type_utils';

import {Input} from '@shared-frontend/components/core/input_v2';

import {
  ensureTicketInfo,
  MaybeTicket,
} from '@src/components/admin/activity_stock/hoobiiz_ticket_info_form';
import {HoobiizTicketInfoMultiForm} from '@src/components/admin/activity_stock/hoobiiz_ticket_info_multi_form';
import {
  FormBlockAuto,
  FormFlex,
  FormLabel,
  FormWrapper,
} from '@src/components/admin/form/form_fragments';
import {HoobiizTimePeriodForm} from '@src/components/admin/form/hoobiiz_time_period_form';
import {OpeningHoursForm} from '@src/components/admin/form/opening_hours_form';
import {adminInputTheme} from '@src/components/core/theme';
import {emptyHoobiizOpeningHours} from '@src/lib/hoobiiz_week_periods';

export interface HoobiizTicketWeeklyScheduleInfo {
  quantity: number;
  timeOfDay: HoobiizTimeOfDay;
  weekday: HoobiizWeekday;
  period: HoobiizTimePeriod;
  ticketInfo: HoobiizTicketInfo[];
}

interface HoobiizTicketWeeklyScheduleProps {
  initialData?: HoobiizTicketWeeklyScheduleInfo[];
  vendorHours: HoobiizOpeningHours;
  onChange?: (newData: HoobiizTicketWeeklyScheduleInfo[] | undefined) => void;
}

const fromString = (val: string): number => {
  const res = parseFloat(val);
  if (isNaN(res) || res < 1) {
    throw new Error('Invalid value');
  }
  return res;
};

interface DataWithMode<Mode extends HoobiizStockMode> {
  id?: HoobiizStockWeeklyTemplateId;
  template?: {weeklyScheduleInfo: HoobiizTicketWeeklyScheduleInfo; mode: Mode; terms: string};
}

function ticketInfoHash(t: HoobiizTicketInfo): string {
  return [
    t.span,
    t.label,
    t.description,
    t.publicPrice.cents,
    t.youpiizPrice.cents,
    t.buyingPrice.cents,
    t.fees?.cents,
    t.minQuantity,
    t.maxQuantity,
    (t.options ?? []).map(o => ticketInfoOptionHash(o)).join('::'),
  ].join(':');
}

function ticketInfoOptionHash(t: HoobiizTicketInfoOption): string {
  return [
    t.label,
    t.description,
    t.publicPrice.cents,
    t.youpiizPrice.cents,
    t.buyingPrice.cents,
    t.minQuantity,
    t.maxQuantity,
  ].join(':');
}

function ticketWeeklyScheduleInfoHash(info: HoobiizTicketWeeklyScheduleInfo): string {
  return [
    info.timeOfDay.startHour,
    info.timeOfDay.startMinute,
    info.timeOfDay.endHour,
    info.timeOfDay.endMinute,
    info.weekday,
  ].join('_');
}

export function mergeTicketInfoAndInitialData<Mode extends HoobiizStockMode>(
  initialData: DataWithMode<Mode>[] | undefined,
  tickets: HoobiizTicketWeeklyScheduleInfo[],
  mode: Mode,
  terms: string
): DataWithMode<Mode>[] {
  if (!initialData) {
    return tickets.map(ticket => ({
      id: undefined,
      template: {
        weeklyScheduleInfo: ticket,
        mode,
        terms,
      },
    }));
  }

  // Try to match data from the handler with the data from initialData
  const initialTemplates = removeUndefined(
    initialData.map(d =>
      d.template && d.id !== undefined ? {template: d.template, id: d.id} : undefined
    )
  );
  const initialHashes = new Map(
    initialTemplates.map(t => [ticketWeeklyScheduleInfoHash(t.template.weeklyScheduleInfo), t.id])
  );
  const newHashes = new Set(tickets.map(t => ticketWeeklyScheduleInfoHash(t)));
  const newData: DataWithMode<Mode>[] = [];

  for (const data of initialData) {
    // Template is not in the new data => it's a DELETE
    if (
      !(
        data.template &&
        newHashes.has(ticketWeeklyScheduleInfoHash(data.template.weeklyScheduleInfo))
      )
    ) {
      newData.push({id: data.id, template: undefined});
    }
  }

  for (const ticket of tickets) {
    // New template is in the old data => it's an UPDATE
    const oldDataId = initialHashes.get(ticketWeeklyScheduleInfoHash(ticket));
    if (oldDataId !== undefined) {
      newData.push({
        id: oldDataId,
        template: {weeklyScheduleInfo: ticket, mode, terms},
      });
    }
    // New template is not in the old data => it's a CREATE
    else {
      newData.push({
        id: undefined,
        template: {weeklyScheduleInfo: ticket, mode, terms},
      });
    }
  }

  return newData;
}

function timeOfDayToDurationMinutes(period: HoobiizTimeOfDay): number {
  return period.endHour * 60 + period.endMinute - (period.startHour * 60 + period.startMinute);
}

function extractSchedule(info: HoobiizTicketWeeklyScheduleInfo[]): {
  schedule: HoobiizOpeningHours;
  period: HoobiizTimePeriod;
  durationMinutes: number;
  quantity: number;
} {
  const [first] = info;
  if (!first) {
    throw new Error('Could not extract schedule info: Not enough info');
  }
  // Start with an empty schedule
  const schedule = emptyHoobiizOpeningHours();
  const {period, timeOfDay} = first;
  const durationMinutes = timeOfDayToDurationMinutes(timeOfDay);
  const {quantity} = first;

  // Add each schedule info to the schedule
  for (const item of info) {
    const duration = timeOfDayToDurationMinutes(item.timeOfDay);
    if (duration !== durationMinutes) {
      throw new Error(`Could not extract schedule info: Duration minutes don't match`);
    }
    if (item.period.startTs !== period.startTs || item.period.endTs !== period.endTs) {
      throw new Error(`Could not extract schedule info: Periods don't match`);
    }
    if (item.quantity !== quantity) {
      throw new Error(`Could not extract schedule info: Quantities don't match`);
    }
    schedule.weekdays[item.weekday].push(item.timeOfDay);
  }

  // Iter each day and merge adjacent periods
  for (const day of iterStringEnum(HoobiizWeekday)) {
    const arr = schedule.weekdays[day];
    arr.sort(compareTimeOfDay);
    const newArr: HoobiizTimeOfDay[] = [];
    for (const period of arr) {
      const last = newArr.at(-1);
      if (!last) {
        newArr.push(period);
      } else if (last.endHour === period.startHour && last.endMinute === period.startMinute) {
        newArr[newArr.length - 1] = {
          startHour: last.startHour,
          startMinute: last.startMinute,
          endHour: period.endHour,
          endMinute: period.endMinute,
        };
      } else {
        newArr.push(period);
      }
    }
    schedule.weekdays[day] = newArr;
  }

  return {schedule, period, durationMinutes, quantity};
}

// HoobiizTicketWeeklyScheduleInfo can contain multiple times the same ticketInfo (one for each day)
function extractTicketInfo(info: HoobiizTicketWeeklyScheduleInfo[]): HoobiizTicketInfo[] {
  const res: HoobiizTicketInfo[] = [];
  for (const {ticketInfo: ticketInfoArr} of info) {
    for (const ticketInfo of ticketInfoArr) {
      const ticketHash = ticketInfoHash(ticketInfo);
      if (res.find(t => ticketInfoHash(t) === ticketHash) === undefined) {
        res.push(ticketInfo);
      }
    }
  }
  return res;
}

export const HoobiizTicketWeeklySchedule: FC<HoobiizTicketWeeklyScheduleProps> = props => {
  const {initialData, vendorHours, onChange} = props;

  const initial = useMemo(() => {
    return !initialData || initialData.length === 0
      ? {schedule: vendorHours, period: initialPeriod(), durationMinutes: 60, quantity: 1}
      : extractSchedule(initialData);
  }, [initialData, vendorHours]);

  const [ticketInfo, setTicketInfo] = useState<MaybeTicket[]>(
    !initialData || initialData.length === 0
      ? [{id: hoobiizTicketInfoId()}]
      : extractTicketInfo(initialData)
  );

  const [schedule, setSchedule] = useState(initial.schedule);
  const [period, setPeriod] = useState(initial.period);
  const [durationMinutes, setDurationMinutes] = useState(initial.durationMinutes);
  const maxDurationMinutes = useMemo(() => {
    return max(values(schedule.weekdays), day => {
      const dayMax = max(day, v => v.endHour * 60 + v.endMinute);
      const dayMin = min(day, v => v.startHour * 60 + v.startMinute);
      if (dayMax === undefined || dayMin === undefined) {
        return 0;
      }
      return dayMax - dayMin;
    });
  }, [schedule.weekdays]);
  const [quantity, setQuantity] = useState(initial.quantity);

  useEffect(() => {
    const info: HoobiizTicketWeeklyScheduleInfo[] = [];

    const res = ensureTicketInfo(ticketInfo);
    if (res.success) {
      for (const [weekday, dayHours] of entries(schedule.weekdays)) {
        for (const hours of dayHours) {
          const {startHour, startMinute, endHour, endMinute} = hours;
          let current = startHour * 60 + startMinute;
          const end = endHour * 60 + endMinute;
          while (current < end) {
            const nextCurrent = current + durationMinutes;
            if (nextCurrent > end) {
              break;
            }
            const currentStartHour = Math.floor(current / 60);
            const currentStartMinute = current % 60;
            const currentEndHour = Math.floor(nextCurrent / 60);
            const currentEndMinute = nextCurrent % 60;
            info.push({
              weekday: weekday as HoobiizWeekday,
              quantity,
              period,
              timeOfDay: {
                startHour: currentStartHour,
                startMinute: currentStartMinute,
                endHour: currentEndHour,
                endMinute: currentEndMinute,
              },
              ticketInfo: res.tickets,
            });
            current = nextCurrent;
          }
        }
      }
      onChange?.(info);
    } else {
      onChange?.(undefined);
    }
  }, [schedule, ticketInfo, durationMinutes, quantity, period, onChange]);

  return (
    <FormWrapper>
      <FormFlex>
        <FormBlockAuto>
          <Input
            value={quantity}
            syncState={setQuantity}
            fromString={fromString}
            label={'QUANTITÉ PAR CRÉNEAUX'}
            overrides={adminInputTheme}
          />
        </FormBlockAuto>
        <FormBlockAuto>
          <Input
            value={durationMinutes}
            syncState={setDurationMinutes}
            fromString={fromString}
            asString={String}
            label={`DURÉE (MINUTES) D'UN CRÉNEAUX`}
            overrides={adminInputTheme}
          />
        </FormBlockAuto>
      </FormFlex>
      <FormBlockAuto>
        <FormLabel>PLAGES HORAIRES DES CRÉNEAUX</FormLabel>
        <OpeningHoursForm openingHours={schedule} onChange={setSchedule} />
      </FormBlockAuto>
      <HoobiizTicketInfoMultiForm
        baseDurationMinutes={durationMinutes}
        maxDurationMinutes={maxDurationMinutes}
        initialData={ticketInfo}
        onChange={setTicketInfo}
      />
      <FormBlockAuto>
        <FormLabel>PÉRIODE DE VALIDITÉ</FormLabel>
        <HoobiizTimePeriodForm initialData={period} onChange={setPeriod} />
      </FormBlockAuto>
    </FormWrapper>
  );
};
HoobiizTicketWeeklySchedule.displayName = 'HoobiizTicketWeeklySchedule';
