import {Dispatch, FC, useCallback, useState} from 'react';
import styled from 'styled-components';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {SearchApiQueryType} from '@shared/api/definitions/search_api';
import {FullItem} from '@shared/model/search_tables';

import {apiCall} from '@shared-frontend/api';
import {Autocomplete} from '@shared-frontend/components/core/autocomplete';

import {adminInputTheme} from '@src/components/core/theme';

interface ExpertTicketProductInputProps {
  product?: FullItem<'HoobiizExpertTicketProduct'>;
  syncState: Dispatch<FullItem<'HoobiizExpertTicketProduct'> | undefined>;
  autoFocus?: boolean;
}

export const ExpertTicketProductInput: FC<ExpertTicketProductInputProps> = props => {
  const {product, syncState, autoFocus} = props;

  const [products, setProducts] = useState<FullItem<'HoobiizExpertTicketProduct'>[]>([]);

  const productLookup = useCallback(
    // eslint-disable-next-line @typescript-eslint/require-await
    async (inputText: string) =>
      products.filter(p => {
        const inputWords = inputText.split(' ').filter(w => w.length > 0);
        for (const word of inputWords) {
          if (!p.ProductName.toUpperCase().includes(word.toUpperCase())) {
            return false;
          }
        }
        return true;
      }),
    [products]
  );

  const lookupInitial = useCallback(
    async () =>
      apiCall(HoobiizApi, '/admin/search/query', {
        table: 'HoobiizExpertTicketProduct',
        mode: 'full',
        permissions: [],
      }).then(res => {
        const {items} = res as SearchApiQueryType<'HoobiizExpertTicketProduct', 'full'>['res'];
        const products = items.map(i => i.item);
        setProducts(products);
        return products;
      }),
    []
  );

  const resultToString = useCallback(
    (res: FullItem<'HoobiizExpertTicketProduct'>) => res.ProductName,
    []
  );
  const resultToKey = useCallback((res: FullItem<'HoobiizExpertTicketProduct'>) => res.id, []);
  const resultToElement = useCallback(
    (res: FullItem<'HoobiizExpertTicketProduct'>, highlighted: boolean) => {
      return (
        <Result $highlighted={highlighted}>
          <ResultProvider>
            <ResultMainText>{res.ProductName}</ResultMainText>
          </ResultProvider>
        </Result>
      );
    },
    []
  );

  return (
    <Autocomplete
      lookupItem={productLookup}
      lookupInitial={lookupInitial}
      item={product}
      syncState={syncState}
      itemToInputString={resultToString}
      itemToKey={resultToKey}
      label={'PRODUCT'}
      overrides={adminInputTheme}
      itemElement={resultToElement}
      autoFocus={autoFocus}
      maxHeight={500}
    />
  );
};
ExpertTicketProductInput.displayName = 'ExpertTicketProductInput';

const Result = styled.div<{$highlighted?: boolean}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;
  padding: 14px;
  cursor: pointer;
  ${p => (p.$highlighted ? `background-color: #f6f6f6;` : `background-color: #ffffff;`)};
  &:hover {
    background-color: #f6f6f6;
  }
`;

const ResultProvider = styled.div`
  display: flex;
  flex-direction: column;
`;

const ResultMainText = styled.span`
  line-height: 20px;
  font-size: 16px;
  font-weight: 500;
`;
