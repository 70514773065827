import {ActivityAndSources} from '@shared/api/definitions/public_api/hoobiiz_api';
import {HoobiizActivityAddressType, HoobiizVendorLocation, WebsiteUrl} from '@shared/dynamo_model';

import {HoobiizVendorLocationWithFrontendId} from '@src/components/admin/vendor/vendor_location_form';
import {emptyHoobiizOpeningHours} from '@src/lib/hoobiiz_week_periods';

// If it returns a single location, it will be the priority location
// If it returns an array, it will be the list of locations
// If it returns a string, it will be a website URL
export function getVendorLocationsWithPriority(
  item: ActivityAndSources
): HoobiizVendorLocation | HoobiizVendorLocation[] | WebsiteUrl {
  // Activity is a website, showing nothing
  if (item.item.address.type === HoobiizActivityAddressType.Website) {
    return item.item.address.websiteUrl;
  }

  // Activity has home address, showing the home address
  if (item.item.address.type === HoobiizActivityAddressType.AtHome) {
    const location = item.item.vendor?.addresses[item.item.address.vendorLocationIndex ?? 0];
    return location ? [location] : [];
  }

  // Activity has a manual address, showing the location (defaulting to old "address" field)
  if (item.item.address.type === HoobiizActivityAddressType.Manual) {
    return (
      item.item.address.location ??
      (item.item.address.address
        ? {
            address: item.item.address.address,
            name: '',
            phone: '',
            openingHours: emptyHoobiizOpeningHours(),
            hideAddress: false,
            hideName: true,
            hidePhone: true,
            hideOpeningHours: true,
          }
        : [])
    );
  }

  // Activity has a vendor address, showing the vendor addresses or the priority address
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (item.item.address.type === HoobiizActivityAddressType.Vendor) {
    for (const source of item.sources ?? []) {
      // There should only be one vendor address source
      if (source.type === 'vendor-address') {
        const address = item.item.vendor?.addresses.at(source.addressIndex);
        if (address !== undefined) {
          return address; // Priority address from search
        }
        break;
      }
    }
    return item.item.vendor?.addresses ?? [];
  }

  // This should never happen
  return [];
}

// This is a function that converts a HoobiizVendorLocationWithFrontendId into a HoobiizVendorLocation
export function vendorLocationWithFrontendIdToVendorLocation(
  location: HoobiizVendorLocationWithFrontendId
): HoobiizVendorLocation {
  const {address, name, openingHours, phone, ...rest} = location;
  if (address === undefined) {
    throw new Error('Adresse manquante');
  }
  if (name === undefined) {
    throw new Error('Nom manquant');
  }
  if (openingHours === undefined) {
    throw new Error('Horaires manquantes');
  }
  if (phone === undefined) {
    throw new Error('Numéro de téléphone manquant');
  }
  return {address, name, openingHours, phone, ...rest};
}
