import {WebsiteUrl} from '@shared/dynamo_model';

export function cleanWebsiteUrl(websiteUrl: WebsiteUrl): string {
  // Remove the protocol
  let url = websiteUrl.replace(/^(?:https?:)?\/\//u, '');
  // Remove the path
  url = url.replace(/\/.*/u, '');
  // Remove the www. prefix
  return url.replace(/^www\./u, '');
}
