import {FC} from 'react';
import styled from 'styled-components';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {useSsrContext} from '@shared/frontends/use_ssr_context';

import {NavLink} from '@shared-frontend/components/core/button';
import {Image} from '@shared-frontend/components/core/image';
import {EmptyFragment} from '@shared-frontend/lib/react';
import {useApiCall} from '@shared-frontend/lib/use_api_call';

import {HoobiizCat1View} from '@src/components/ui/hoobiiz_cat1_view';
import questionMark from '@src/images/question_mark.png';
import {isHoobiizHost} from '@src/lib/hoobiiz_context';
import {getCat1Url} from '@src/lib/hoobiiz_urls';

interface HoobiizCat1ListViewProps {}

export const HoobiizCat1ListView: FC<HoobiizCat1ListViewProps> = () => {
  const {data} = useApiCall(HoobiizApi, '/list-cat1', {});
  const ctx = useSsrContext();

  if (data === undefined) {
    return <div>Loading...</div>;
  }

  return (
    <Wrapper>
      <Title>
        <Expand>Des milliers d’activités au meilleur prix !</Expand>
        {isHoobiizHost(ctx) ? (
          <NavLink to="/youpiiz-cest-quoi">
            <Image
              srcAndSizes={{srcData: questionMark, width: 45}}
              height={40}
              alt="Youpiiz c'est quoi ?"
            />
          </NavLink>
        ) : (
          EmptyFragment
        )}
      </Title>
      <Grid>
        {data.cat1.map(cat => (
          <HoobiizCat1View key={cat.id} to={getCat1Url(cat)} name={cat.name} media={cat.media} />
        ))}
      </Grid>
    </Wrapper>
  );
};

HoobiizCat1ListView.displayName = 'HoobiizCat1ListView';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));
  gap: 16px;
`;

const Title = styled.div`
  font-size: 30px;
  font-weight: 700;
  display: flex;
`;

const Expand = styled.div`
  flex-grow: 1;
`;
