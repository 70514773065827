import {FC, Fragment, useCallback, useState} from 'react';
import styled from 'styled-components';

import {EmailString} from '@shared/dynamo_model';
import {useTheme} from '@shared/frontends/theme_context';
import {neverHappens} from '@shared/lib/type_utils';

import {sharedApiCall} from '@shared-frontend/api';
import {Button, NavLink, UnthemedNavLink} from '@shared-frontend/components/core/button';
import {Input} from '@shared-frontend/components/core/input_v2';
import {Spacing} from '@shared-frontend/components/core/spacing';
import {notifyError} from '@shared-frontend/lib/notification';
import {EmptyFragment} from '@shared-frontend/lib/react';
import {optionalPx} from '@shared-frontend/lib/styled_utils';

type ForgotPasswordFormMode = 'initial' | 'finish';

export const ForgotPassword: FC = () => {
  const {
    main: {api, textColor, logo},
  } = useTheme();

  const [email, setEmail] = useState<string | undefined>();
  const [formMode, setFormMode] = useState<ForgotPasswordFormMode>('initial');

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleEmailSubmit = useCallback(() => {
    setIsSubmitting(true);
    sharedApiCall(api, '/forgot-password', {email: email as EmailString})
      .then(data => {
        if (data.success) {
          setFormMode('finish');
        } else {
          notifyError(`Échec de l'envoi de l'email`);
        }
      })
      .catch(err => notifyError(err))
      .finally(() => setIsSubmitting(false));
  }, [api, email]);

  let formContent = EmptyFragment;

  if (formMode === 'initial') {
    formContent = (
      <Fragment>
        <Title>Mot de passe oublié ?</Title>
        <Subtitle>Réinitialisez votre mot de passe</Subtitle>
        <Separator $color={textColor} $top={22} $bottom={18} />
        <FormInput>
          <Input
            width="100%"
            type="email"
            value={email}
            syncState={setEmail}
            placeholder="jean@exemple.com"
            label="ADRESSE EMAIL"
            autoComplete="email"
            autoFocus
          />
        </FormInput>
        <Spacing height={28} />
        <LegalText>
          En continuant, vous reconnaissez avoir lu les{' '}
          <NavLink to="/terms">mentions légales</NavLink>
        </LegalText>
        <Spacing height={28} />
        <Button expand submit disabled={isSubmitting}>
          Recevoir un lien par email
        </Button>
      </Fragment>
    );
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  } else if (formMode === 'finish') {
    formContent = (
      <Fragment>
        <Title>Lien envoyé</Title>
        <Spacing height={24} />
        <Subtitle>
          Nous vous avons envoyé un email contenant un lien. Cliquez sur le lien pour modifier votre
          mot de passe. Vous pouvez fermer cette page.
        </Subtitle>
      </Fragment>
    );
  } else {
    neverHappens(formMode);
  }

  return (
    <Form onSubmit={handleEmailSubmit}>
      <LogoNavLink to="/">{logo}</LogoNavLink>
      <Spacing height={24} />
      {formContent}
    </Form>
  );
};

ForgotPassword.displayName = 'ForgotPassword';

const Form = styled.form`
  width: 95vw;
  max-width: 360px;
  height: 100%;
  margin: auto;
  padding: 32px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1.5;
`;

const LogoNavLink = styled(UnthemedNavLink)`
  height: 64px;
  & > * {
    width: 100%;
    height: 100%;
  }
`;

const Title = styled.h3`
  font-weight: 700;
  font-size: 160%;
`;

const Subtitle = styled.p`
  opacity: 0.6;
  font-weight: 400;
`;

const FormInput = styled.div`
  width: 100%;
  text-align: left;
`;

const Separator = styled.div<{$color: string; $top?: number; $bottom?: number}>`
  height: 1px;
  width: 100%;
  background-color: ${p => p.$color}20;
  ${p => optionalPx('margin-top', p.$top)}
  ${p => optionalPx('margin-bottom', p.$bottom)}
`;

const LegalText = styled.div`
  width: 100%;
  text-align: left;
  font-size: 80%;
`;
