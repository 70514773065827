/* eslint-disable @typescript-eslint/no-magic-numbers */
import {FC, JSX} from 'react';
import styled from 'styled-components';

import {LinkAsButton} from '@shared-frontend/components/core/button';
import {Image} from '@shared-frontend/components/core/image';

import {Colors} from '@src/components/core/theme_base';
import customerPageHeader from '@src/images/customer_page_header.png';
import customerPageIcon1 from '@src/images/customer_page_icon1.png';
import customerPageIcon2 from '@src/images/customer_page_icon2.png';
import customerPageIcon3 from '@src/images/customer_page_icon3.png';
import partnerPagePartner1 from '@src/images/partner_page_partner1.png';
import partnerPagePartner2 from '@src/images/partner_page_partner2.png';
import partnerPagePartner3 from '@src/images/partner_page_partner3.png';
import partnerPagePartner4 from '@src/images/partner_page_partner4.png';
import partnerPagePartner5 from '@src/images/partner_page_partner5.png';
import partnerPageStarIcon from '@src/images/partner_page_star_icon.png';
import partnerPageStep1 from '@src/images/partner_page_step1.png';
import partnerPageStep2 from '@src/images/partner_page_step2.png';
import partnerPageStep3 from '@src/images/partner_page_step3.png';

interface CustomerPageProps {}

function makeIcon(image: ImageSrcData, width: number, alt: string): JSX.Element {
  return <Image srcAndSizes={{srcData: image, width}} height={100} alt={alt} />;
}

function makeCustomerImage(image: ImageSrcData, width: number, alt: string): JSX.Element {
  return <Image srcAndSizes={{srcData: image, width}} height={80} alt={alt} />;
}

function makeHowItWorksIcon(image: ImageSrcData, alt: string): JSX.Element {
  return <Image srcAndSizes={{srcData: image, width: 187}} height={80} alt={alt} />;
}

export const CustomerPage: FC<CustomerPageProps> = () => {
  return (
    <Wrapper>
      {/* HEADER */}
      <Image
        srcAndSizes={{srcData: customerPageHeader, width: 1920}}
        width="calc(100% + 48px)"
        alt="Devenir partenaire !"
      />
      <CustomerHeader>Bienvenue sur Youpiiz.com&nbsp;!</CustomerHeader>
      <br />
      <HeaderText>
        La destination incontournable pour vos loisirs et activités au meilleur prix.
        <br />
        <br />
        Sur Youpiiz.com, nous proposons une vaste sélection d’activités pour tous les goûts et tous
        les âges. Que vous soyez à la recherche de sensations fortes, de balades, d’activités
        manuelles ou culturelles, de vacances ou de moments de détente. Entre amis, collègue de
        travail ou en famille nous avons ce qu’il vous faut.
        <br />
        <br />
        Selon nos partenaires et le type d’activité nous proposons différentes façon de bénéficier
        de nos offres&nbsp;:
      </HeaderText>

      {/* GRID */}
      <Grid>
        <GridItem>
          <GridItemTitle>Réservation en ligne&nbsp;!</GridItemTitle>
          {makeIcon(customerPageIcon1, 213, `Réservation en ligne !`)}
          <GridItemContent>Réservez directement votre créneau en ligne&nbsp;!</GridItemContent>
        </GridItem>
        <GridItem>
          <GridItemTitle>Achat de e-billets&nbsp;!</GridItemTitle>
          {makeIcon(customerPageIcon2, 190, `Achat de e-billets !`)}
          <GridItemContent>
            Achetez vos billets et profitez en directement chez nos partenaires&nbsp;!
          </GridItemContent>
        </GridItem>
        <GridItem>
          <GridItemTitle>Codes promos&nbsp;!</GridItemTitle>
          {makeIcon(customerPageIcon3, 173, `Codes promos !`)}
          <GridItemContent>
            Bénéficiez d’offres exceptionnelles sur les sites de nos partenaires&nbsp;!
          </GridItemContent>
        </GridItem>
      </Grid>

      {/* Partner Trust */}
      <CustomerHeader>Ils nous font déjà confiance&nbsp;:</CustomerHeader>
      <PartnersList>
        {makeCustomerImage(partnerPagePartner1, 213, 'PortAventura Park')}
        {makeCustomerImage(partnerPagePartner2, 283, 'Parc Astérix')}
        {makeCustomerImage(partnerPagePartner3, 431, 'Disneyland Paris')}
        {makeCustomerImage(partnerPagePartner4, 283, 'Futuroscope')}
        {makeCustomerImage(partnerPagePartner5, 257, 'Incroyable Grévin')}
      </PartnersList>

      {/* How it works */}
      <HowItWorksHeader>
        <Image srcAndSizes={{srcData: partnerPageStarIcon, width: 417}} height={64} alt="star" />
        <HowItWorksTitle>Youpiiz.com comment ça marche&nbsp;?</HowItWorksTitle>
        <Image srcAndSizes={{srcData: partnerPageStarIcon, width: 417}} height={64} alt="star" />
      </HowItWorksHeader>
      <HowItWorksList>
        <HowItWorksItem>
          {makeHowItWorksIcon(partnerPageStep1, 'step 1')}
          <HowItWorksContent>Je m’inscris via le formulaire d’inscription.</HowItWorksContent>
        </HowItWorksItem>
        <HowItWorksSeparator1 />
        <HowItWorksItem>
          {makeHowItWorksIcon(partnerPageStep2, 'step 2')}
          <HowItWorksContent>Je découvre des milliers d’activités&nbsp;!</HowItWorksContent>
        </HowItWorksItem>
        <HowItWorksSeparator2 />
        <HowItWorksItem>
          {makeHowItWorksIcon(partnerPageStep3, 'step 3')}
          <HowItWorksContent>Je bénéficie d’offres exceptionnelles&nbsp;!</HowItWorksContent>
        </HowItWorksItem>
      </HowItWorksList>

      <HeaderQuestion>
        Les offres “premium” sont réservées aux bénéficiaires d’une organisation&nbsp;:
      </HeaderQuestion>

      <HeaderText>
        Les offres qui comportent la mention “offre premium” sont accessibles uniquement aux membres
        d’une organisation ; entreprises, CSE, associations, amicales & assimilés.
        <br />
        <br />
        <Red>
          L’adhésion est totalement gratuite pour les organisations tout comme pour les
          bénéficiaires.
        </Red>
        <br />
        Pour accéder aux offres votre entreprise vous à remis un code premium à saisir lors de votre
        inscription dans “ CODE PREMIUM “ ou à ajouter dans votre compte après inscription.
        <br />
        <br />
        Si vous n’avez pas de code vous pouvez en faire la demande pour bénéficier rapidement de
        toutes les offres&nbsp;:
      </HeaderText>
      <br />

      <Centered>
        <LinkAsButton
          href="https://www.youpiiz.fr/contact"
          overrides={{borderRadius: 23, fontSize: '1.5vmax'}}
        >
          Contactez-nous
        </LinkAsButton>
      </Centered>

      {/* Commission */}
      <CommissionSection>
        <CommissionSep />
        <CommissionQuote>
          Youpiiz.com c’est des milliers d’offres pour les loisirs ; du cinéma au paintball en
          passant par le parc enfant, le musée, le parc d’attraction ou même le cours de boxe. Mais
          aussi pour le bien être, la relaxation et les vacances. Réservez votre séance de
          naturopathie, d’astrologie , votre transat sur la plage ou votre massage. On a même des
          offres sur vos vacances en camping ou au Club Med&nbsp;!
        </CommissionQuote>
        <CommissionSep />
      </CommissionSection>
    </Wrapper>
  );
};

CustomerPage.displayName = 'CustomerPage';

const Wrapper = styled.div`
  max-width: 1920px;
  display: flex;
  flex-direction: column;
  & > picture:first-child {
    overflow: visible;
    margin: -16px 0 1vmax -48px;
  }
`;

const HeaderText = styled.div`
  text-align: center;
  font-size: 1.9vmax;
  line-height: 150%;
  padding: 0 16px;
`;

const HeaderQuestion = styled.div`
  text-align: center;
  font-size: 1.8vmax;
  font-weight: 700;
  line-height: 150%;
  padding: 16px;
  background-color: #faf6f2;
  border-radius: 16px;
  margin: 32px 16px 16px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 300px);
  grid-gap: 1.9vmax;
  justify-content: center;
  padding: 16px;
`;

const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 16px;
  background-color: #faf6f2;
  border-radius: 8px;
  border: solid 2px #a3917f;
`;
const GridItemTitle = styled.div`
  width: 100%;
  min-height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 120%;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  background: #ffffffaa;
  padding: 8px 16px;
  border-radius: 16px;
  box-shadow: 0 0 10px 2px #c4a86614;
`;
const GridItemContent = styled.div`
  text-align: center;
  line-height: 150%;
`;

const CustomerHeader = styled.div`
  text-align: center;
  font-size: 1.9vmax;
  font-weight: 600;
  line-height: 150%;
  margin: 1vmax 0 1.5vmax;
`;
const PartnersList = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 32px;
`;

const HowItWorksHeader = styled.div`
  display: flex;
  gap: 1vmax;
  align-items: center;
  justify-content: center;
  margin: 48px 1vmax 24px 1vmax;
  & > * {
    flex-shrink: 0;
  }
`;
const HowItWorksTitle = styled.div`
  flex-grow: 0;
  flex-shrink: 1;
  text-align: center;
  font-size: 2.5vmax;
  font-weight: 600;
  line-height: 150%;
  margin: 1.5vmax 0 1.5vmax;
`;
const HowItWorksList = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  gap: 32px;
`;
const HowItWorksItem = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  padding: 8px;
  max-width: 200px;
`;
const HowItWorksContent = styled.div`
  line-height: 150%;
  text-align: center;
`;
const HowItWorksSeparatorBase = styled.div`
  width: 6px;
  height: 75px;
  align-self: center;
  background-color: ${Colors.LightGold};
`;
const HowItWorksSeparator1 = styled(HowItWorksSeparatorBase)`
  @media (max-width: 471px) {
    /* background-color: transparent; */
    display: none;
  }
`;
const HowItWorksSeparator2 = styled(HowItWorksSeparatorBase)`
  @media (max-width: 741px) {
    /* background-color: transparent; */
    display: none;
  }
`;

const CommissionSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin: 32px;
`;
const CommissionSep = styled.div`
  width: 250px;
  height: 2px;
  background-color: ${Colors.LightGold};
`;
const CommissionQuote = styled.div`
  font-style: italic;
  line-height: 150%;
  text-align: center;
  max-width: 800px;
`;

const Red = styled.div`
  color: #df1a07;
  font-weight: 700;
`;

const Centered = styled.div`
  display: flex;
  justify-content: center;
`;
