import {FC, useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {SearchApiGetType, SearchApiQueryType} from '@shared/api/definitions/search_api';
import {ApiDef} from '@shared/api/registry';
import {HoobiizActivityId} from '@shared/dynamo_model';
import {groupBy} from '@shared/lib/array_utils';
import {stockHasChanged, templateHasChanged} from '@shared/lib/hoobiiz/compare_hoobiiz_model';
import {FullItem} from '@shared/model/search_tables';

import {apiCall} from '@shared-frontend/api';
import {NavButton} from '@shared-frontend/components/core/button';
import {LoadingIndicator} from '@shared-frontend/components/core/loading_indicator';
import {showSuccess} from '@shared-frontend/components/core/notifications';
import {notifyError} from '@shared-frontend/lib/notification';

import {
  ActivityStockForm,
  ActivityStockFormProps,
} from '@src/components/admin/activity_stock/activity_stock_form';
import {FormWrapper} from '@src/components/admin/form/form_fragments';
import {FormSection} from '@src/components/admin/form/form_section';
import {PageWrapper} from '@src/components/admin/page_fragment';
import {Colors} from '@src/components/core/theme_base';
import {emptyHoobiizOpeningHours} from '@src/lib/hoobiiz_week_periods';

interface ActivityStockPageProps {
  activityId: HoobiizActivityId;
}

export const ActivityStockPage: FC<ActivityStockPageProps> = props => {
  const {activityId} = props;
  // const navigate = useNavigate();

  const [activity, setActivity] = useState<FullItem<'HoobiizActivity'> | undefined>();
  const [stocks, setStocks] = useState<FullItem<'HoobiizStock'>[] | undefined>();
  const [stockTemplates, setStockTemplates] = useState<
    FullItem<'HoobiizStockWeeklyTemplate'>[] | undefined
  >();
  const [isLoading, setIsLoading] = useState(true);

  const reload = useCallback(() => {
    // Reset states
    setActivity(undefined);
    setStocks(undefined);
    setStockTemplates(undefined);
    setIsLoading(true);

    // Load all data
    Promise.all([
      apiCall(HoobiizApi, '/admin/search/get', {
        table: 'HoobiizActivity',
        id: activityId,
        mode: 'full',
        permissions: [],
      }),
      apiCall(HoobiizApi, '/admin/search/query', {
        table: 'HoobiizStock',
        gsi: {
          name: 'activityId',
          where: {type: 'equal', value: activityId},
        },
        mode: 'full',
        permissions: [],
      }),
      apiCall(HoobiizApi, '/admin/search/query', {
        table: 'HoobiizStockWeeklyTemplate',
        gsi: {
          name: 'activityId',
          where: {type: 'equal', value: activityId},
        },
        mode: 'full',
        permissions: [],
      }),
    ])
      .then(([res1, res2, res3]) => {
        const {item: activityItem} = res1 as SearchApiGetType<'HoobiizActivity', 'full'>['res'];
        if (!activityItem) {
          throw new Error('Activity not found');
        }
        setActivity(activityItem);
        const {items: stockItems} = res2 as SearchApiQueryType<'HoobiizStock', 'full'>['res'];
        setStocks(
          stockItems
            .map(i => i.item)
            .filter(s => s.weeklyTemplateId === undefined)
            .sort((s1, s2) => s1.createdAt - s2.createdAt)
        );
        const {items: stockTemplateItems} = res3 as SearchApiQueryType<
          'HoobiizStockWeeklyTemplate',
          'full'
        >['res'];
        setStockTemplates(
          stockTemplateItems.map(i => i.item).sort((s1, s2) => s1.createdAt - s2.createdAt)
        );
        setIsLoading(false);
      })
      .catch(err => {
        notifyError(err, {
          message:
            "Échec du chargement des stocks de l'activité. Vous pouvez rafraichir la page pour réessayer",
        });
        setIsLoading(false);
      });
  }, [activityId]);

  useEffect(reload, [reload]);

  const handleSubmit = useCallback<ActivityStockFormProps['onSubmit']>(
    async data => {
      const operations: ApiDef<typeof HoobiizApi>['/admin/manage-stocks']['req'] = [];

      for (const d of data) {
        const stock = 'ticket' in d ? d.ticket : 'template' in d ? d.template : undefined;

        // CREATION
        if (d.id === undefined) {
          if (stock) {
            operations.push({createStock: stock});
          } else {
            // should not happen
            notifyError('Invalid state', {extra: d});
          }
          continue;
        }

        if ('ticket' in d) {
          if (d.ticket !== undefined) {
            const previousTicket = stocks?.find(s => s.id === d.id);
            if (!previousTicket) {
              throw new Error(`Failure to identify stock with id ${d.id}`);
            }
            if (stockHasChanged(d.ticket, previousTicket)) {
              operations.push({updateStock: {id: d.id, updates: d.ticket}});
            }
          } else {
            operations.push({deleteStock: d.id});
          }
        } else if ('template' in d) {
          if (d.template !== undefined) {
            const previousTemplate = stockTemplates?.find(s => s.id === d.id);
            if (!previousTemplate) {
              throw new Error(`Failure to identify template with id ${d.id}`);
            }
            if (templateHasChanged(d.template, previousTemplate)) {
              operations.push({updateStock: {id: d.id, updates: d.template}});
            }
          } else {
            operations.push({deleteStock: d.id});
          }
        } else {
          // should not happen
        }
      }

      await apiCall(HoobiizApi, '/admin/manage-stocks', operations)
        .then(() => {
          reload();
          showSuccess(`Stocks mis à jour`);
        })
        .catch(err => {
          reload();
          notifyError(err, {message: `Erreur lors de la mise à jour des stocks`});
        });
    },
    [reload, stockTemplates, stocks]
  );

  if (!activity || !stocks || !stockTemplates || isLoading) {
    return (
      <LoadingPage>
        Chargement des stocks de l'activité
        <LoadingIndicator opacity={0.9} size={64} thickness={5} />
      </LoadingPage>
    );
  }

  const vendorHours = activity.vendor?.addresses[0]?.openingHours ?? emptyHoobiizOpeningHours();

  const stocksByBatchId = groupBy(stocks, s => s.batchId);
  const stockTemplatesByBatchId = groupBy(stockTemplates, s => s.batchId);
  // Sort by most recent
  const stockBatches = [...stocksByBatchId.values(), ...stockTemplatesByBatchId.values()].sort(
    (e1, e2) => e2[0].updatedAt - e1[0].updatedAt
  );
  const stockForms = stockBatches.map((batch, i) => (
    <FormSection key={batch[0].id} title={`Stock #${i + 1}`}>
      <ActivityStockForm
        activityId={activityId}
        vendorHours={vendorHours}
        vendorEmail={activity.vendor?.contact.email}
        initialData={batch}
        submitButtonText="Modifier"
        onSubmit={handleSubmit}
      />
    </FormSection>
  ));

  return (
    <FormWrapper>
      <NavButton to={`/admin/activity/${activityId}/stock/create`}>Créer un stock</NavButton>
      {stockForms}
    </FormWrapper>
  );
};
ActivityStockPage.displayName = 'ActivityStockPage';

const LoadingPage = styled(PageWrapper)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 48px;
  width: 100%;
  height: 100%;
  font-size: 3vw;
  color: ${Colors.Gold};
  opacity: 0.6;
`;
