import {FC, useCallback, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router';
import styled from 'styled-components';

import {HoobiizApi, HoobiizData} from '@shared/api/definitions/public_api/hoobiiz_api';
import {SearchApiGetType} from '@shared/api/definitions/search_api';
import {HoobiizCat3Id, HoobiizCat3Item} from '@shared/dynamo_model';
import {FullItem} from '@shared/model/search_tables';

import {apiCall} from '@shared-frontend/api';
import {LoadingIndicator} from '@shared-frontend/components/core/loading_indicator';
import {showSuccess} from '@shared-frontend/components/core/notifications';
import {notifyError} from '@shared-frontend/lib/notification';

import {Category3Form} from '@src/components/admin/category/category3_form';
import {FormTitle, FormWrapper} from '@src/components/admin/form/form_fragments';
import {PageWrapper} from '@src/components/admin/page_fragment';
import {Colors} from '@src/components/core/theme_base';

export const Category3UpdatePage: FC = () => {
  const navigate = useNavigate();
  const {cat1Id, cat2Id, cat3Id: cat3IdStr = ''} = useParams();
  const cat3Id = cat3IdStr as HoobiizCat3Id;

  const [cat3, setCat3] = useState<FullItem<'HoobiizCat3'> | undefined>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    apiCall(HoobiizApi, '/admin/search/get', {
      table: 'HoobiizCat3',
      id: cat3Id,
      mode: 'full',
      permissions: [],
    })
      .then(res => {
        const {item} = res as SearchApiGetType<'HoobiizCat3', 'full'>['res'];
        setCat3(item);
        setIsLoading(false);
      })
      .catch(err => {
        notifyError(err, {
          message:
            'Échec du chargement de la catégorie 3. Vous pouvez rafraichir la page pour réessayer',
        });
        setIsLoading(false);
      });
  }, [cat3Id]);

  const handleSubmit = useCallback(
    async (cat3Data: Omit<HoobiizData<HoobiizCat3Item>, 'order'>) => {
      const {cat3} = await apiCall(HoobiizApi, '/admin/update-cat3', {
        id: cat3Id,
        updates: cat3Data,
      });
      showSuccess(`Catégorie 3 "${cat3.name}" mise à jour`);
      navigate(`/admin/cat1/${cat1Id}/cat2/${cat2Id}`);
    },
    [cat3Id, navigate, cat1Id, cat2Id]
  );

  const handleDelete = useCallback(async () => {
    await apiCall(HoobiizApi, '/admin/delete-cat3', {id: cat3Id});
    showSuccess(`Catégorie 3 "${cat3?.name}" supprimé`);
    navigate(`/admin/cat1/${cat1Id}/cat2/${cat2Id}`);
  }, [cat3Id, cat3?.name, navigate, cat1Id, cat2Id]);

  if (!cat3 || isLoading) {
    return (
      <LoadingPage>
        Chargement du partenaire
        <LoadingIndicator opacity={0.9} size={64} thickness={5} />
      </LoadingPage>
    );
  }
  return (
    <PageWrapper>
      <FormWrapper>
        <FormTitle>{`Modifier la catégorie 3 ${cat3.name}`}</FormTitle>
        <Category3Form
          initialData={cat3}
          submitButtonText="Modifier"
          onSubmit={handleSubmit}
          onDelete={handleDelete}
        />
      </FormWrapper>
    </PageWrapper>
  );
};
Category3UpdatePage.displayName = 'Category3UpdatePage';

const LoadingPage = styled(PageWrapper)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 48px;
  width: 300%;
  height: 300%;
  font-size: 3vw;
  color: ${Colors.Gold};
  opacity: 0.6;
`;
