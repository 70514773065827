import {FC, MouseEventHandler, useCallback, useState} from 'react';
import styled from 'styled-components';

import {HoobiizData} from '@shared/api/definitions/public_api/hoobiiz_api';
import {HoobiizMediaId, HoobiizVendorItem, HoobiizVisibility, Markdown} from '@shared/dynamo_model';
import {uidUnsafe} from '@shared/lib/rand';
import {asError} from '@shared/lib/type_utils';
import {FullItem} from '@shared/model/search_tables';

import {Button, ButtonAsLink} from '@shared-frontend/components/core/button';
import {Input} from '@shared-frontend/components/core/input_v2';
import {SvgIcon} from '@shared-frontend/components/core/svg_icon';
import {Textarea} from '@shared-frontend/components/core/textarea_v2';
import {notifyError} from '@shared-frontend/lib/notification';
import {EmptyFragment} from '@shared-frontend/lib/react';

import {ExternalUrlLink} from '@src/components/admin/form/external_url_link';
import {
  FormBlock,
  FormBlockFull,
  FormFlex,
  FormLabel,
} from '@src/components/admin/form/form_fragments';
import {FormSection} from '@src/components/admin/form/form_section';
import {HoobiizVisibilityForm} from '@src/components/admin/form/hoobiiz_visibility_form';
import {MarkdownHelpLabel} from '@src/components/admin/form/markdown_help_label';
import {MediaUpload} from '@src/components/admin/form/media_upload';
import {MediaUploadStatus} from '@src/components/admin/form/media_upload_status';
import {VendorContactForm} from '@src/components/admin/vendor/vendor_contact_form';
import {
  HoobiizVendorLocationWithFrontendId,
  VendorLocationForm,
} from '@src/components/admin/vendor/vendor_location_form';
import {adminInputTheme, AlertButton} from '@src/components/core/theme';
import {Colors, Sizes} from '@src/components/core/theme_base';
import {HoobiizVendorPageView} from '@src/components/ui/hoobiiz_vendor_page_view';
import {toSlugName} from '@src/lib/hoobiiz_urls';
import {vendorLocationWithFrontendIdToVendorLocation} from '@src/lib/hoobiiz_vendor_location';

interface VendorFormProps {
  initialData?: Partial<FullItem<'HoobiizVendor'>>;
  submitButtonText?: string;
  onSubmit?: (data: HoobiizData<HoobiizVendorItem>) => Promise<void>;
  onDelete?: () => Promise<void>;
}

export const VendorForm: FC<VendorFormProps> = props => {
  const {initialData, submitButtonText, onSubmit, onDelete} = props;

  const [name, setName] = useState(initialData?.name ?? '');
  const [slugName, setSlugName] = useState(
    initialData?.slugName ?? toSlugName(initialData?.name ?? '')
  );
  const [addresses, setAddresses] = useState<HoobiizVendorLocationWithFrontendId[]>(
    initialData?.addresses?.map(address => ({...address, id: uidUnsafe()})) ?? [{id: uidUnsafe()}] // Empty address for the first location
  );

  const setAddress = useCallback((address: HoobiizVendorLocationWithFrontendId) => {
    setAddresses(prevAddresses => prevAddresses.map(a => (a.id === address.id ? address : a)));
  }, []);

  const updateName = useCallback((str: string) => {
    setName(prevName => {
      // Only update slug name and address name if it was not manually set
      setSlugName(prevSlugName => {
        return prevSlugName === toSlugName(prevName) ? toSlugName(str) : prevSlugName;
      });
      return str;
    });
  }, []);

  const [comments, setComments] = useState(initialData?.comments ?? '');

  const [contact, setContact] = useState(
    initialData?.contact ?? {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    }
  );
  const [markdown, setMarkdown] = useState(initialData?.markdown ?? ('' as Markdown));
  const mediaWithIds = initialData?.mediaIds?.map(id => ({
    id,
    media: initialData.media?.find(m => m.id === id),
  }));
  const [media, setMedia] = useState(mediaWithIds);
  const logoWithId = {
    id: initialData?.logoMediaId,
    media: initialData?.logo,
  };
  const [logo, setLogo] = useState(logoWithId);
  const [visibility, setVisibility] = useState(initialData?.visibility ?? HoobiizVisibility.Public);

  const [previewHidden, setPreviewHidden] = useState(false);
  const handlePreviewClick = useCallback(() => setPreviewHidden(hidden => !hidden), []);

  const handleCreateClick = useCallback(async () => {
    if (addresses.length === 0) {
      notifyError('Adresse manquante');
      return;
    }
    try {
      return onSubmit?.({
        name,
        slugName,
        addresses: addresses.map(vendorLocationWithFrontendIdToVendorLocation),
        contact,
        markdown,
        visibility,
        mediaIds: media?.map(media => media.id) ?? [],
        logoMediaId: logo.id,
        comments,
      });
    } catch (err: unknown) {
      notifyError(asError(err).message);
    }
  }, [
    addresses,
    comments,
    contact,
    logo.id,
    markdown,
    media,
    name,
    onSubmit,
    slugName,
    visibility,
  ]);

  const handleMediaChange = useCallback((statuses: MediaUploadStatus[]) => {
    const media = [] as {media: FullItem<'HoobiizMedia'> | undefined; id: HoobiizMediaId}[];
    for (const status of statuses) {
      if (status.status === 'success') {
        media.push({media: status.media, id: status.id});
      }
    }
    setMedia(media);
  }, []);

  const handleLogoChange = useCallback((statuses: MediaUploadStatus[]) => {
    const [status] = statuses;
    if (!status) {
      setLogo({id: undefined, media: undefined});
      return;
    }
    if (status.status === 'success') {
      setLogo({media: status.media, id: status.id});
    }
  }, []);

  const handleAddClick = useCallback(() => {
    setAddresses(prev => [...prev, {id: uidUnsafe()}]);
  }, []);

  const handleDeleteClick = useCallback<MouseEventHandler>(evt => {
    const index = evt.currentTarget.getAttribute('data-index');
    setAddresses(prev => prev.filter((_, i) => i !== Number(index)));
  }, []);

  return (
    <Wrapper>
      <FormWrapper>
        {/* INFO */}
        <FormSection title="INFO">
          <FormFlex>
            <FormBlock>
              <Input
                width="100%"
                value={name}
                syncState={updateName}
                placeholder={initialData?.name}
                label="NOM DU PARTENAIRE"
                autoFocus
                overrides={adminInputTheme}
              />
            </FormBlock>
            <FormBlock>
              <Input
                width="100%"
                value={slugName}
                syncState={setSlugName}
                placeholder={initialData?.slugName}
                label="NOM DANS L'URL"
                overrides={adminInputTheme}
              />
            </FormBlock>
            <FormBlock>
              <HoobiizVisibilityForm value={visibility} syncState={setVisibility} />
            </FormBlock>
          </FormFlex>
          <FormBlockFull>
            <FormLabel>LOGO</FormLabel>
            <MediaUpload
              initialMedia={
                logoWithId.id === undefined ? [] : [{id: logoWithId.id, media: logoWithId.media}]
              }
              onChange={handleLogoChange}
            />
          </FormBlockFull>
          <FormBlockFull>
            <Textarea
              width="100%"
              value={comments}
              syncState={setComments}
              placeholder={initialData?.comments}
              label="BLOC NOTES (non visible sur le site)"
              overrides={adminInputTheme}
              height={200}
            />
          </FormBlockFull>
          {initialData?.externalUrl === undefined ? (
            EmptyFragment
          ) : (
            <ExternalUrlLink url={initialData.externalUrl} />
          )}
        </FormSection>

        {/* ÉTABLISSEMENT */}
        {addresses.map((address, index) => (
          <FormSection title={`ÉTABLISSEMENT #${index + 1}`} key={`location_${index}`}>
            <VendorLocationForm location={address} onChange={setAddress} />
            {addresses.length > 1 ? (
              <DeleteTicketButton data-index={index} onClick={handleDeleteClick}>
                <SvgIcon name="Trash" color={Colors.RedLight} size={14} />
                Supprimer
              </DeleteTicketButton>
            ) : (
              EmptyFragment
            )}
          </FormSection>
        ))}
        <AddLocationButton onClick={handleAddClick}>
          <PlusIconWrapper>
            <SvgIcon name="Plus" color="#ffffff" size={8} />
          </PlusIconWrapper>
          Ajouter un établissement
        </AddLocationButton>

        {/* INTERLOCUTEUR */}
        <FormSection title="INTERLOCUTEUR">
          <VendorContactForm initialData={contact} onChange={setContact} />
        </FormSection>

        {/* PAGE */}
        <FormSection title="PAGE">
          <FormFlex>
            <FormBlockFull>
              <Textarea
                width="100%"
                height={400}
                value={markdown}
                syncState={setMarkdown}
                placeholder={initialData?.markdown}
                label={<MarkdownHelpLabel title="CONTENU" />}
                overrides={adminInputTheme}
              />
            </FormBlockFull>
            <FormBlockFull>
              <FormLabel>IMAGES</FormLabel>
              <MediaUpload initialMedia={mediaWithIds} multiple onChange={handleMediaChange} />
            </FormBlockFull>
          </FormFlex>
        </FormSection>

        {/* BUTTONS */}
        <ButtonsWrapper>
          <Button onClickAsync={handleCreateClick} disabled={addresses.length === 0}>
            {submitButtonText}
          </Button>
          {onDelete ? <AlertButton onClickAsync={onDelete}>Supprimer</AlertButton> : EmptyFragment}
        </ButtonsWrapper>
      </FormWrapper>
      <PreviewSection
        title="APERÇUS"
        titleComponent={
          <ButtonAsLink onClick={handlePreviewClick}>
            {previewHidden ? 'Agrandir' : 'Réduire'}
          </ButtonAsLink>
        }
        noMargin
        $hidden={previewHidden}
      >
        <StyledHoobiizVendorPageView
          vendorId={initialData?.id}
          name={name}
          media={media}
          info={markdown}
          locations={addresses}
        />
      </PreviewSection>
    </Wrapper>
  );
};
VendorForm.displayName = 'VendorForm';

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 32px;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  flex-grow: 1;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PreviewSection = styled(FormSection)<{$hidden: boolean}>`
  position: sticky;
  top: 32px;
  max-height: calc(100vh - 64px);
  overflow-y: scroll;
  flex-shrink: 0;
  ${p => p.$hidden && `margin-right: -450px;`}
  transition: margin-right ease-in-out 300ms;
`;

const StyledHoobiizVendorPageView = styled(HoobiizVendorPageView)`
  zoom: 0.5;
  width: ${Sizes.Content};
  margin-top: 69px;
`;

const AddLocationButton = styled(ButtonAsLink)`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const PlusIconWrapper = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 1000px;
  background-color: ${Colors.Gold};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DeleteTicketButton = styled(ButtonAsLink)`
  display: flex;
  align-items: center;
  gap: 6px;
  position: absolute;
  top: -2px;
  right: -2px;
  color: ${Colors.RedLight};
  font-size: 15px;
  background: transparent;
  border: solid 2px transparent;
  border-radius: 4px;
  padding: 7px 10px;
  transition: none;
  &:hover {
    color: ${Colors.Red};
    background: #fef4f4;
    border: solid 2px ${Colors.Red};
    & svg {
      fill: ${Colors.Red};
    }
  }
`;
