import {FC} from 'react';
import {useLocation} from 'react-router';
import styled from 'styled-components';

import {useTheme} from '@shared/frontends/theme_context';

import {UnthemedNavLink} from '@shared-frontend/components/core/button';

interface AdminNavProps {
  nav: {label: string; path: string; otherPaths?: string[]}[];
}

export const AdminNav: FC<AdminNavProps> = props => {
  const {nav = []} = props;
  const location = useLocation();
  const {
    main: {accentColor, accentTextColor, logo},
  } = useTheme();
  return (
    <Container $backgroundColor={accentColor} $color={accentTextColor}>
      <LogoWrapper>
        <LogoNavLink to="/">{logo}</LogoNavLink>
      </LogoWrapper>
      {nav.map(({label, path, otherPaths}) => (
        <NavigationLink to={path} key={path} $forceActive={otherPaths?.includes(location.pathname)}>
          {label}
        </NavigationLink>
      ))}
    </Container>
  );
};
AdminNav.displayName = 'AdminNav';

const Container = styled.div<{$backgroundColor: string; $color: string}>`
  width: 100%;
  height: 100%;
  background-color: ${p => p.$backgroundColor};
  overflow-y: auto;
  color: ${p => p.$color};
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 24px 0;
`;

const NavigationLink = styled(UnthemedNavLink)<{$forceActive?: boolean}>`
  display: block;
  width: 100%;
  padding: 20px;
  font-size: 20px;
  text-decoration: none;

  &:hover {
    background-color: #ffffff15;
  }

  &.active {
    background-color: #00000040;
  }
  ${p => p.$forceActive && 'background-color: #00000040 !important;'}
`;

const LogoNavLink = styled(UnthemedNavLink)`
  height: 64px;
  /* & > * {
    width: 100%;
    height: 100%;
  } */
`;
