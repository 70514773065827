import {FC} from 'react';

import {HoobiizActivityAddress, HoobiizActivityAddressType} from '@shared/dynamo_model';
import {FullItem} from '@shared/model/search_tables';

import {ActivityAddressAtHomeForm} from '@src/components/admin/activity/activity_address_at_home_form';
import {ActivityAddressManualForm} from '@src/components/admin/activity/activity_address_manual_form';
import {ActivityAddressVendorForm} from '@src/components/admin/activity/activity_address_vendor_form';
import {ActivityAddressWebsiteForm} from '@src/components/admin/activity/activity_address_website_form';
import {MultiForm} from '@src/components/admin/form/multi_form';

interface ActivityAddressFormProps {
  initialData?: HoobiizActivityAddress;
  onChange: (newVal: HoobiizActivityAddress | undefined) => void;
  vendor?: FullItem<'HoobiizVendor'>;
}

export const ActivityAddressForm: FC<ActivityAddressFormProps> = props => {
  const {initialData, onChange, vendor} = props;

  return (
    <MultiForm
      forms={{
        [HoobiizActivityAddressType.Vendor]: ActivityAddressVendorForm,
        [HoobiizActivityAddressType.AtHome]: ActivityAddressAtHomeForm,
        [HoobiizActivityAddressType.Website]: ActivityAddressWebsiteForm,
        [HoobiizActivityAddressType.Manual]: ActivityAddressManualForm,
      }}
      labels={[
        {label: 'Adresse du partenaire', value: HoobiizActivityAddressType.Vendor},
        {label: 'À domicile', value: HoobiizActivityAddressType.AtHome},
        {label: 'Site Web', value: HoobiizActivityAddressType.Website},
        {label: 'Autre', value: HoobiizActivityAddressType.Manual},
      ]}
      onChange={onChange}
      initialType={initialData?.type}
      initialData={initialData}
      extra={{vendor}}
    />
  );
};
ActivityAddressForm.displayName = 'ActivityAddressForm';
