import {FC} from 'react';
import styled from 'styled-components';

import {HoobiizVendorLocation} from '@shared/dynamo_model';
import {DAYS_IN_WEEK, startOfUtcDay} from '@shared/lib/date_utils';
import {capitalize} from '@shared/lib/format_utils';
import {addressToLines} from '@shared/lib/hoobiiz/activity_address_and_phone';

import {EmptyFragment} from '@shared-frontend/lib/react';

import {HoobiizGoogleMaps} from '@src/components/ui/hoobiiz_google_maps';
import {DAYS, formatTimeOfDay} from '@src/lib/hoobiiz_week_periods';

interface HoobiizLocationComponentProps extends Partial<HoobiizVendorLocation> {}

export const HoobiizLocationComponent: FC<HoobiizLocationComponentProps> = props => {
  const {
    name,
    hideName,
    phone,
    hidePhone = true,
    address,
    hideAddress,
    openingHours,
    hideOpeningHours,
    rangeInKm,
  } = props;
  const addressLines = address ? addressToLines(address) : {line1: undefined, line2: undefined};
  const showLeft = !(hideAddress && hidePhone && hideOpeningHours);

  return (
    <Wrapper>
      {showLeft ? (
        <Left>
          {address && !(hideAddress && hidePhone) ? (
            <LeftCol1>
              {hideAddress ? (
                EmptyFragment
              ) : (
                <Section>
                  <Subtitle>Adresse</Subtitle>
                  <ContactLines>
                    {hideName ? (
                      EmptyFragment
                    ) : (
                      <div>
                        {rangeInKm !== undefined
                          ? `Déplacement chez vous dans un rayon de ${rangeInKm} km à partir de : ${name}`
                          : name}
                      </div>
                    )}
                    <div>{addressLines.line1 ?? 'Adresse non renseignée'}</div>
                    <div>{addressLines.line2}</div>
                  </ContactLines>
                </Section>
              )}
              {hidePhone ? (
                EmptyFragment
              ) : (
                <Section>
                  <Subtitle>Contact</Subtitle>
                  <div>{phone}</div>
                </Section>
              )}
            </LeftCol1>
          ) : (
            EmptyFragment
          )}
          {hideOpeningHours ? (
            EmptyFragment
          ) : (
            <LeftCol2>
              <Subtitle>Heures d'ouverture</Subtitle>
              <Hours>
                {DAYS.map(({label, value, weekDay}) => {
                  const now = new Date();
                  const dayOfWeekDelta = (weekDay - now.getDay() + DAYS_IN_WEEK) % DAYS_IN_WEEK;
                  const nextDate = startOfUtcDay(now, dayOfWeekDelta);
                  const times = openingHours?.weekdays[value] ?? [];
                  const override = openingHours?.overrides?.find(o => o.day === nextDate.getTime());
                  const lines = [
                    <HoursLine key={value} $noBorder={override !== undefined}>
                      <div>{capitalize(label.toLowerCase())}</div>
                      <HoursLineRight>
                        {times.length === 0 ? (
                          <div key="closed">Fermé</div>
                        ) : (
                          times.map(t => <div key={formatTimeOfDay(t)}>{formatTimeOfDay(t)}</div>)
                        )}
                      </HoursLineRight>
                    </HoursLine>,
                  ];
                  if (override) {
                    const overrideDate = new Date(override.day);
                    const overrideDateStr = [
                      overrideDate.getDate().toString().padStart(2, '0'),
                      (overrideDate.getUTCMonth() + 1).toString().padStart(2, '0'),
                      overrideDate.getUTCFullYear(),
                    ].join('/');
                    const reasonStr = override.reason.length > 0 ? ` (${override.reason})` : '';
                    lines.push(
                      <HoursLine key="override">
                        <HoursLineOverride>{`Fermé le ${overrideDateStr}${reasonStr}`}</HoursLineOverride>
                      </HoursLine>
                    );
                  }
                  return lines;
                })}
              </Hours>
            </LeftCol2>
          )}
        </Left>
      ) : (
        EmptyFragment
      )}
      {IS_BROWSER && address && !hideAddress ? (
        <Right>
          <HoobiizGoogleMaps name={hideName ? undefined : name} address={address} />
        </Right>
      ) : (
        EmptyFragment
      )}
    </Wrapper>
  );
};

HoobiizLocationComponent.displayName = 'HoobiizLocationComponent';

const Subtitle = styled.div`
  font-size: 17px;
  font-weight: 700;
`;

const Wrapper = styled.div`
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
  white-space: nowrap;
`;

const Left = styled.div`
  width: 0;
  min-width: 400px;
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
`;

const LeftCol1 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 0;
  flex-grow: 1;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ContactLines = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  white-space: normal;
`;

const LeftCol2 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 0;
  flex-grow: 1;
`;

const Right = styled.div`
  width: 0;
  min-width: 400px;
  flex-grow: 1;
`;

const Hours = styled.div`
  display: flex;
  flex-direction: column;
  border-top: solid 1px #eee;
  max-width: 300px;
`;

const HoursLine = styled.div<{$noBorder?: boolean}>`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  gap: 4px;
  ${p => !p.$noBorder && 'border-bottom: solid 1px #eee;'}
  padding: 4px 0;
`;

const HoursLineRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
const HoursLineOverride = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #ba7d28;
  width: 100%;
  text-align: right;
`;
