import {FC, ReactNode} from 'react';
import {useLocation} from 'react-router-dom';
import styled from 'styled-components';

import {NavButton, NavLink} from '@shared-frontend/components/core/button';
import {useSession} from '@shared-frontend/lib/session_store';

interface LoginContainerProps {
  children: ReactNode;
}

export const LoginContainer: FC<LoginContainerProps> = ({children}) => {
  const location = useLocation();
  const session = useSession();

  return session === undefined ? (
    <Container>
      <NavButton to="/login" state={{from: location.pathname}}>
        Connectez-vous
      </NavButton>
      <RegisterContainer>
        <div>Pas encore inscrit ?</div>
        <NavLink to="/register" state={{from: location.pathname}}>
          Créer un compte
        </NavLink>
      </RegisterContainer>
    </Container>
  ) : (
    children
  );
};

LoginContainer.displayName = 'LoginContainer';

const Container = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
`;

const RegisterContainer = styled.div`
  display: flex;
  gap: 4px;
  justify-content: center;
`;
