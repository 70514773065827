import {useCallback} from 'react';
import {useLocation, useNavigate} from 'react-router';
import {styled} from 'styled-components';

import {ActivityAndSources, HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';

import {apiCall} from '@shared-frontend/api';
import {Autocomplete} from '@shared-frontend/components/core/autocomplete';
import {Custom, EmptyFragment} from '@shared-frontend/lib/react';

import {largeInputTheme} from '@src/components/core/theme';
import {HoobiizMediaView} from '@src/components/ui/hoobiiz_media_view';
import {HoobiizPricesView} from '@src/components/ui/hoobiiz_prices';
import {HoobiizSearchLocation} from '@src/components/ui/hoobiiz_search_location';
import {getActivityUrl} from '@src/lib/hoobiiz_urls';
import {getVendorLocationsWithPriority} from '@src/lib/hoobiiz_vendor_location';

interface SearchBarProps {
  className?: string;
}

export const SearchBar: Custom<SearchBarProps, 'div'> = props => {
  const {className} = props;
  const navigate = useNavigate();
  const location = useLocation();

  const lookupItem = useCallback(async (query: string) => {
    const res = await apiCall(HoobiizApi, '/search-activity', {query});
    return res.activities;
  }, []);

  const renderItem = useCallback((item: ActivityAndSources, highlighted: boolean) => {
    const vendorName = item.item.vendor?.name ?? 'Youpiiz';
    const discount = item.item.bestStock.discount;
    // Fetching the city of the vendor based on the search results
    // If a user searches for a specific city, the vendor city displayed may change accordingly (vendor with multiple addresses)
    let cityText = '';
    let vendorLocations = getVendorLocationsWithPriority(item);
    if (typeof vendorLocations !== 'string') {
      if (!Array.isArray(vendorLocations)) {
        vendorLocations = [vendorLocations];
      }
      if (vendorLocations[0]?.address.addressDetails.city !== undefined) {
        cityText = ` à ${vendorLocations[0].address.addressDetails.city}`;
      }
    }
    return (
      <SearchResult $highlighted={highlighted}>
        <SearchResultMedia media={item.item.media[0]} size={{width: 80, height: 80}} />
        <SearchResultContent>
          <SearchResultTitle>{item.item.label}</SearchResultTitle>
          <SearchResultSubtitle>
            {vendorName}
            {cityText}
          </SearchResultSubtitle>
          {discount ? (
            <HoobiizPricesView prices={discount} prefix="Dès" justify="flex-start" />
          ) : (
            // Out of stock
            EmptyFragment
          )}
        </SearchResultContent>
      </SearchResult>
    );
  }, []);

  const itemToInputString = useCallback((item: ActivityAndSources) => item.item.label, []);
  const itemToKey = useCallback((item: ActivityAndSources) => item.item.id, []);

  const handleSelect = useCallback(
    (item?: ActivityAndSources) => {
      if (item) {
        navigate(getActivityUrl(item));
      }
    },
    [navigate]
  );

  return (
    <Wrapper>
      <Autocomplete
        lookupItem={lookupItem}
        minChar={3}
        overrides={largeInputTheme}
        itemElement={renderItem}
        itemToInputString={itemToInputString}
        itemToKey={itemToKey}
        syncState={handleSelect}
        autoFocus={location.pathname === '/'}
        // eslint-disable-next-line react/forbid-component-props
        className={className}
        placeholder="Rechercher par mots-clés"
      />
      <HoobiizSearchLocation />
    </Wrapper>
  );
};
SearchBar.displayName = 'SearchBar';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
`;

const SearchResult = styled.div<{$highlighted: boolean}>`
  display: flex;
  gap: 8px;
  background-color: ${p => (p.$highlighted ? '#eeeeee' : '#ffffff')};
  padding: 8px;
  &:hover {
    background-color: #eeeeee;
  }
`;

const SearchResultMedia = styled(HoobiizMediaView)`
  flex-shrink: 0;
  border-radius: 4px;
  overflow: hidden;
`;
const SearchResultContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SearchResultTitle = styled.div`
  font-size: 18px;
`;
const SearchResultSubtitle = styled.div`
  font-size: 15px;
  color: #666666;
`;
