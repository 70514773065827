import {FC, MouseEventHandler, useCallback, useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router';
import styled from 'styled-components';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {SearchApiQueryType} from '@shared/api/definitions/search_api';
import {useTheme} from '@shared/frontends/theme_context';
import {useSsrContext} from '@shared/frontends/use_ssr_context';
import {randomStringUnsafe} from '@shared/lib/rand';
import {FullItem} from '@shared/model/search_tables';

import {apiCall} from '@shared-frontend/api';
import {Input} from '@shared-frontend/components/core/input_v2';
import {SvgIcon} from '@shared-frontend/components/core/svg_icon';
import {AgeString} from '@shared-frontend/components/core/timestamp';
import {notifyError} from '@shared-frontend/lib/notification';
import {isMac} from '@shared-frontend/lib/platform';
import {EmptyFragment} from '@shared-frontend/lib/react';

import {
  Table,
  TableHeaderCell,
  TableHeaderText,
  TableImage,
  TableLineCell,
  TableLineCellWithImage,
} from '@src/components/admin/table_fragment';
import {adminInputTheme} from '@src/components/core/theme';
import {mediaSrcData} from '@src/lib/hoobiiz_media';
import {HoobiizVisibilityLabels} from '@src/lib/hoobiiz_visibility';

interface ActivityTableProps {}

export const ActivityTable: FC<ActivityTableProps> = () => {
  const {
    main: {accentColor, accentTextColor},
  } = useTheme();
  const navigate = useNavigate();
  const {host} = useSsrContext();

  const [activities, setActivities] = useState([] as FullItem<'HoobiizActivity'>[]);
  const [totalActivities, setTotalActivities] = useState<number | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const loadingToken = useRef('');
  const [loadingParams, setLoadingParams] = useState<
    SearchApiQueryType<'HoobiizActivity', 'full'>['req']
  >({
    table: 'HoobiizActivity',
    gsi: {name: 'updatedAt', desc: true},
    mode: 'full',
    permissions: [],
  });

  const [searchQuery, setSearchQuery] = useState('');

  // Trigger data fetch when the loadingParams changes
  useEffect(() => {
    const reqLoadingToken = randomStringUnsafe(10);
    setIsLoading(true);
    loadingToken.current = reqLoadingToken;
    apiCall(HoobiizApi, '/admin/search/query', loadingParams)
      .then(res => {
        if (loadingToken.current !== reqLoadingToken) {
          return;
        }
        const {items, total} = res as SearchApiQueryType<'HoobiizActivity', 'full'>['res'];
        setActivities(items.map(i => i.item));
        setTotalActivities(total);
        setIsLoading(false);
      })
      .catch(err => {
        if (loadingToken.current !== reqLoadingToken) {
          return;
        }
        notifyError(err, {message: 'Erreur lors du chargement des activités'});
        setIsLoading(false);
      });
  }, [loadingParams]);

  // When clicking a column to change the sorting
  const handleHeaderClick = useCallback<MouseEventHandler>(evt => {
    const sort = evt.currentTarget.getAttribute('data-sort');
    setLoadingParams(loadingParams => ({
      table: 'HoobiizActivity',
      gsi: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        name: sort as any,
        desc: loadingParams.gsi?.name === sort && !loadingParams.gsi.desc,
      },
      mode: 'full',
      permissions: [],
    }));
  }, []);

  // When clicking a line to open a provider
  const handleLineClick = useCallback<MouseEventHandler>(
    evt => {
      const id = evt.currentTarget.getAttribute('data-id');
      const openInNewTab = (isMac() && evt.metaKey) || (!isMac() && evt.ctrlKey);
      const path = `/admin/activity/${id}`;
      if (openInNewTab) {
        window.open(`${window.location.origin}${path}`);
      } else {
        navigate(path);
      }
    },
    [navigate]
  );

  // Utility to generate the sort icon on the column headers
  const sortIcon = useCallback(
    (sortName: string) => {
      const {name, desc} = loadingParams.gsi ?? {};
      if (name !== sortName) {
        return EmptyFragment;
      }
      return (
        <SvgIcon name={desc ? 'ChevronUp' : 'ChevronDown'} size={12} color={accentTextColor} />
      );
    },
    [accentTextColor, loadingParams.gsi]
  );

  return (
    <Wrapper>
      <Input
        placeholder="Filtrer"
        value={searchQuery}
        syncState={setSearchQuery}
        overrides={adminInputTheme}
        width="100%"
      />
      <Pagination>
        {totalActivities === undefined
          ? ''
          : `${totalActivities.toLocaleString()} activités dans le système`}
      </Pagination>
      <Table
        $isLoading={isLoading}
        $headerBackgroundColor={accentColor}
        $headerColor={accentTextColor}
      >
        {/* HEADER */}
        <thead>
          <tr>
            <th>
              <TableHeaderCell>
                <TableHeaderText>Partenaire</TableHeaderText>
              </TableHeaderCell>
            </th>
            <th>
              <TableHeaderCell $clickable onClick={handleHeaderClick} data-sort={'name'}>
                <TableHeaderText>Activité</TableHeaderText>
                {sortIcon('name')}
              </TableHeaderCell>
            </th>
            <th>
              <TableHeaderCell $clickable onClick={handleHeaderClick} data-sort={'createdAt'}>
                <TableHeaderText>Création</TableHeaderText>
                {sortIcon('createdAt')}
              </TableHeaderCell>
            </th>
            <th>
              <TableHeaderCell $clickable onClick={handleHeaderClick} data-sort={'updatedAt'}>
                <TableHeaderText>Dernière modification</TableHeaderText>
                {sortIcon('updatedAt')}
              </TableHeaderCell>
            </th>
            <th>
              <TableHeaderCell $clickable onClick={handleHeaderClick} data-sort={'visibility'}>
                <TableHeaderText>Status</TableHeaderText>
                {sortIcon('visibility')}
              </TableHeaderCell>
            </th>
          </tr>
        </thead>
        {/* BODY */}
        <tbody>
          {activities
            .filter(activity =>
              searchQuery.length === 0
                ? true
                : // Includes activities where the activity label or the vendor name matches the search query
                  activity.label.toLocaleUpperCase().includes(searchQuery.toLocaleUpperCase()) ||
                  activity.vendor?.name
                    .toLocaleUpperCase()
                    .includes(searchQuery.toLocaleUpperCase())
            )
            .map(activity => {
              const {
                vendor,
                id,
                label,
                media: allMedia,
                mediaIds,
                createdAt,
                updatedAt,
                visibility,
              } = activity;
              const [media] = allMedia;
              const [mediaId] = mediaIds;
              const vendorLogo = vendor?.logo;
              return (
                <tr key={id} onClick={handleLineClick} data-id={id}>
                  <td>
                    <TableLineCellWithImage $noWrapp>
                      {vendorLogo ? (
                        <TableImage
                          alt={vendor.name}
                          srcAndSizes={{
                            srcData: mediaSrcData(host, vendorLogo),
                            width: 64,
                          }}
                          rawUrls
                          width={64}
                          height={64}
                        />
                      ) : (
                        EmptyFragment
                      )}
                      {vendor?.name}
                    </TableLineCellWithImage>
                  </td>
                  <td>
                    <TableLineCellWithImage $noWrapp>
                      {media || mediaId !== undefined ? (
                        <TableImage
                          alt={label}
                          srcAndSizes={{
                            srcData: mediaSrcData(
                              host,
                              media
                                ? {
                                    media,
                                    id: media.id,
                                  }
                                : mediaId !== undefined
                                  ? {id: mediaId}
                                  : undefined
                            ),
                            width: 64,
                          }}
                          rawUrls
                          width={64}
                          height={64}
                        />
                      ) : (
                        EmptyFragment
                      )}
                      {label}
                    </TableLineCellWithImage>
                  </td>
                  <td>
                    <TableLineCell $noWrapp>
                      <AgeString ts={createdAt} ago lang="fr-FR" />
                    </TableLineCell>
                  </td>
                  <td>
                    <TableLineCell $noWrapp>
                      <AgeString ts={updatedAt} ago lang="fr-FR" />
                    </TableLineCell>
                  </td>
                  <td>
                    <TableLineCell $noWrapp>{HoobiizVisibilityLabels[visibility]}</TableLineCell>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </Wrapper>
  );
};
ActivityTable.displayName = 'ActivityTable';

const Wrapper = styled.div``;

const Pagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 32px;
  font-size: 14px;
  color: #555;
`;
