import {FC} from 'react';
import styled from 'styled-components';

import {useSsrContext} from '@shared/frontends/use_ssr_context';

import {NavLink} from '@shared-frontend/components/core/button';

import {Sizes} from '@src/components/core/theme_base';

export const TermsPage: FC = () => {
  const {host} = useSsrContext();

  return (
    <Wrapper>
      <Title>Mentions légales</Title>
      <Text>
        Le site <CustomNavLink to="/">{host}</CustomNavLink> est édité par la société :
      </Text>
      <Title>PASSION & COMMUNICATION</Title>
      <Text>178 Boulevard des Ecoles</Text>
      <Text>34750 VILLENEUVE LES MAGUELONE</Text>
      <Text>SARL au capital de 500.00 euros</Text>
      <Text>Immatriculée au RCS Montpellier sous le numéro 532 708 583</Text>
      <Text>Directeur de publication : Julien Swoboda</Text>
      <Text>Email : (hello@youpiiz.com)</Text>
      <Title>Hébergement</Title>
      <Text>L’hébergement est assuré par la société :</Text>
      <Text>Amazon Web Services, Inc.</Text>
      <Text>P.O. Box 81226</Text>
      <Text>Seattle, WA 98108-1226</Text>
      <Title>Information concernant les données</Title>
      <Text>
        Conformément à la loi « informatique et libertés » du 6 janvier 1978 modifiée en 2004, vous
        bénéficiez d’un droit d’accès et de rectification aux informations qui vous concernent.
      </Text>
      <Text>Vous pouvez exercer ce droit en nous contactant :</Text>
      <Text> - Par mail : (hello@youpiiz.com)</Text>
      <Text>
        {' '}
        - Par courrier : YOUPIIZ - 178 boulevard des Ecoles – 34750 VILLENEUVE LES MAGUELONE.
      </Text>
      <Text>
        Vous pouvez également vous opposer au traitement des données vous concernant en nous le
        signalant aux adresses précédemment mentionnées.
      </Text>
    </Wrapper>
  );
};

TermsPage.displayName = 'TermsPage';

const Wrapper = styled.div`
  width: 100%;
  max-width: ${Sizes.Content};
  margin: auto;
  padding: 60px 16px;
`;

const CustomNavLink = styled(NavLink)`
  text-decoration: underline;
`;

const Title = styled.div`
  font-size: 30px;
  margin: 20px 0;
`;

const Text = styled.p`
  margin: 10px 0;
`;
