import {FC, useEffect, useState} from 'react';

import {
  HoobiizActivityAddressType,
  HoobiizActivityAddressWebsite,
  WebsiteUrl,
} from '@shared/dynamo_model';

import {Input} from '@shared-frontend/components/core/input_v2';

import {FormBlock, FormFlex} from '@src/components/admin/form/form_fragments';
import {adminInputTheme} from '@src/components/core/theme';

interface ActivityAddressWebsiteFormProps {
  initialData?: HoobiizActivityAddressWebsite;
  onChange: (newVal: HoobiizActivityAddressWebsite | undefined) => void;
}

export const ActivityAddressWebsiteForm: FC<ActivityAddressWebsiteFormProps> = props => {
  const {initialData, onChange} = props;

  // State to set the website url
  const [websiteUrl, setWebsiteUrl] = useState<WebsiteUrl>(
    initialData?.websiteUrl ?? ('' as WebsiteUrl)
  );

  useEffect(() => {
    onChange({
      type: HoobiizActivityAddressType.Website,
      websiteUrl,
    });
  }, [onChange, websiteUrl]);

  // Display an input to set the website url
  return (
    <FormFlex>
      <FormBlock>
        <Input
          width="100%"
          label="LIEN DU SITE WEB (obligatoire)"
          type="string"
          value={websiteUrl}
          syncState={setWebsiteUrl}
          overrides={adminInputTheme}
          placeholder="https://www.example.com"
        />
      </FormBlock>
    </FormFlex>
  );
};
ActivityAddressWebsiteForm.displayName = 'ActivityAddressWebsiteForm';
