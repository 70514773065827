import {
  HoobiizDiscount,
  HoobiizDiscountPercent,
  HoobiizDiscountType,
  HoobiizPrices,
} from '@shared/dynamo_model';
import {cents, currencyAmountToString, roundCents} from '@shared/lib/hoobiiz/currency_amount';
import {
  baseHoobiizPrices,
  flattenHoobiizPrices,
  hoobiizPricesToDiscountPercent,
} from '@shared/lib/hoobiiz/hoobiiz_prices';
import {applyDiscount, isPositiveDiscount} from '@shared/lib/hoobiiz/offer';
import {neverHappens} from '@shared/lib/type_utils';
import {SanitizedItem, SanitizedTicketInfo} from '@shared/model/search_tables';

import {Colors} from '@src/components/core/theme_base';

export const noDiscount: HoobiizDiscountPercent = {type: HoobiizDiscountType.Percent, percent: 0};

export function discountToLongString(discount: HoobiizDiscount): string {
  if (discount.type === HoobiizDiscountType.Amount) {
    return `${currencyAmountToString(discount.amount)} de réduction`;
  }
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  else if (discount.type === HoobiizDiscountType.Percent) {
    return `${Math.round(discount.percent).toLocaleString()}% de réduction`;
  }
  neverHappens(discount, `Invalid discount type "${(discount as HoobiizDiscount).type}"`);
}

export function discountToString(discount: HoobiizDiscount): string {
  if (discount.type === HoobiizDiscountType.Amount) {
    return `-${currencyAmountToString(discount.amount)}`;
  }
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  else if (discount.type === HoobiizDiscountType.Percent) {
    return `-${Math.round(discount.percent).toLocaleString()}%`;
  }
  neverHappens(discount, `Invalid discount type "${(discount as HoobiizDiscount).type}"`);
}

export function getDiscount(
  ticketInfo: Pick<SanitizedTicketInfo, 'publicPrice' | 'youpiizPrice'> | undefined,
  offers: SanitizedItem<'HoobiizOffer'>[]
): {
  amount: HoobiizPrices;
  totalDiscount: HoobiizDiscountPercent;
  totalDiscountColor: string;
  bestOffer?: SanitizedItem<'HoobiizOffer'>;
} {
  if (!ticketInfo) {
    return {
      amount: baseHoobiizPrices(cents(0)),
      totalDiscount: {type: HoobiizDiscountType.Percent, percent: 0},
      totalDiscountColor: Colors.Grey,
    };
  }

  const price1 = ticketInfo.publicPrice;
  const price2 = ticketInfo.youpiizPrice;
  let price3 = ticketInfo.youpiizPrice;

  let bestOffer: SanitizedItem<'HoobiizOffer'> | undefined;
  for (const offer of offers) {
    if (isPositiveDiscount(offer.discount)) {
      const newPrice = applyDiscount(offer.discount, price2);
      if (newPrice.cents < roundCents(price3.cents)) {
        bestOffer = offer;
        price3 = newPrice;
      }
    }
  }

  const amount = {price1, price2, price3};
  const prices = flattenHoobiizPrices(amount);
  const discountCount = prices.length - 1;
  const totalDiscountColor =
    discountCount === 0 ? Colors.Grey : discountCount === 1 ? Colors.Green : Colors.Red;

  return {
    amount: {price1, price2, price3},
    totalDiscount: hoobiizPricesToDiscountPercent(amount),
    totalDiscountColor,
    bestOffer,
  };
}

const discountGradient = [
  {
    percent: 0,
    color: '#3a9200',
  },
  {
    percent: 3,
    color: '#8e9b01',
  },
  {
    percent: 10,
    color: '#d19d00',
  },
  {
    percent: 15,
    color: '#d18f00',
  },
  {
    percent: 20,
    color: '#de5900',
  },
  {
    percent: 25,
    color: '#e03f00',
  },
  {
    percent: 30,
    color: '#c60000',
  },
].reverse();
export function getDiscountColor(discount?: HoobiizDiscountPercent): string {
  const percent = Math.round(discount?.percent ?? 0);
  return discountGradient.find(g => percent >= g.percent)?.color ?? '#488023';
}
