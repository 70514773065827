import {FC, useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router';
import styled from 'styled-components';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {SearchApiGetType} from '@shared/api/definitions/search_api';
import {HoobiizActivityId} from '@shared/dynamo_model';
import {removeUndefined} from '@shared/lib/type_utils';
import {FullItem} from '@shared/model/search_tables';

import {apiCall} from '@shared-frontend/api';
import {LoadingIndicator} from '@shared-frontend/components/core/loading_indicator';
import {showSuccess} from '@shared-frontend/components/core/notifications';
import {notifyError} from '@shared-frontend/lib/notification';
import {useParams} from '@shared-frontend/lib/use_typed_params';

import {
  ActivityStockForm,
  ActivityStockFormProps,
} from '@src/components/admin/activity_stock/activity_stock_form';
import {FormSection} from '@src/components/admin/form/form_section';
import {PageWrapper} from '@src/components/admin/page_fragment';
import {Colors} from '@src/components/core/theme_base';

interface ActivityStockCreatePageProps {}

export const ActivityStockCreatePage: FC<ActivityStockCreatePageProps> = () => {
  const {id = ''} = useParams();
  const navigate = useNavigate();

  const activityId = id as HoobiizActivityId;

  const [activity, setActivity] = useState<FullItem<'HoobiizActivity'> | undefined>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    apiCall(HoobiizApi, '/admin/search/get', {
      table: 'HoobiizActivity',
      id: activityId,
      mode: 'full',
      permissions: [],
    })
      .then(res => {
        const {item} = res as SearchApiGetType<'HoobiizActivity', 'full'>['res'];
        setActivity(item);
        setIsLoading(false);
      })
      .catch(err => {
        notifyError(err, {
          message:
            "Échec du chargement de l'activité. Vous pouvez rafraichir la page pour réessayer",
        });
        setIsLoading(false);
      });
  }, [activityId]);

  const handleSubmit = useCallback<ActivityStockFormProps['onSubmit']>(
    async data => {
      await apiCall(
        HoobiizApi,
        '/admin/create-stocks',
        removeUndefined(
          data.map(d => ('ticket' in d ? d.ticket : 'template' in d ? d.template : undefined))
        )
      );
      showSuccess(`Stock de tickets créé`);
      navigate(`/admin/activity/${activityId}?tab=stock`);
    },
    [activityId, navigate]
  );

  if (!activity || isLoading) {
    return (
      <LoadingPage>
        Chargement de l'activité
        <LoadingIndicator opacity={0.9} size={64} thickness={5} />
      </LoadingPage>
    );
  }

  const openingHours = activity.vendor?.addresses[0]?.openingHours;
  if (!activity.vendor || !openingHours) {
    return <LoadingPage>Partenaire de l'activité invalide.</LoadingPage>;
  }

  return (
    <PageWrapper>
      <FormSection title={`Nouveau Stock`}>
        <ActivityStockForm
          vendorHours={openingHours}
          vendorEmail={activity.vendor.contact.email}
          activityId={activityId}
          submitButtonText="Créer"
          onSubmit={handleSubmit}
        />
      </FormSection>
    </PageWrapper>
  );
};
ActivityStockCreatePage.displayName = 'ActivityStockCreatePage';

const LoadingPage = styled(PageWrapper)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 48px;
  width: 100%;
  height: 100%;
  font-size: 3vw;
  color: ${Colors.Gold};
  opacity: 0.6;
`;
