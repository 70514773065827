import {FC, Fragment} from 'react';
import styled from 'styled-components';

import {ActivityAndSources} from '@shared/api/definitions/public_api/hoobiiz_api';
import {
  HoobiizDiscount,
  HoobiizDiscountType,
  HoobiizVendorLocation,
  WebsiteUrl,
} from '@shared/dynamo_model';
import {substractCurrencyAmount} from '@shared/lib/hoobiiz/currency_amount';
import {discountIsZero, hoobiizPricesToDiscountPercent} from '@shared/lib/hoobiiz/hoobiiz_prices';

import {UnthemedNavLink} from '@shared-frontend/components/core/button';
import {Image} from '@shared-frontend/components/core/image';
import {SvgIcon} from '@shared-frontend/components/core/svg_icon';
import {EmptyFragment} from '@shared-frontend/lib/react';

import {Colors, FontWeight} from '@src/components/core/theme_base';
import {HoobiizMediaView} from '@src/components/ui/hoobiiz_media_view';
import {HoobiizPricesView} from '@src/components/ui/hoobiiz_prices';
import customerPageIcon1 from '@src/images/customer_page_icon1.png';
import customerPageIcon2 from '@src/images/customer_page_icon2.png';
import customerPageIcon3 from '@src/images/customer_page_icon3.png';
import {discountToLongString} from '@src/lib/discount';
import {getActivityUrl} from '@src/lib/hoobiiz_urls';
import {getVendorLocationsWithPriority} from '@src/lib/hoobiiz_vendor_location';
import {cleanWebsiteUrl} from '@src/lib/website_url';

interface HoobiizActivityCardProps {
  activityAndSources: ActivityAndSources;
  clickable: boolean;
}

export const HoobiizActivityCard: FC<HoobiizActivityCardProps> = ({
  activityAndSources,
  clickable,
}) => {
  const {id, label, media, vendor, bestStock, cse, promoCodeStock} = activityAndSources.item;
  const vendorLocations = getVendorLocationsWithPriority(activityAndSources);
  let singleLocation: HoobiizVendorLocation | undefined;
  let websiteUrl: WebsiteUrl | undefined;
  if (Array.isArray(vendorLocations)) {
    if (vendorLocations.length === 1) {
      singleLocation = vendorLocations[0];
    }
  } else if (typeof vendorLocations === 'string') {
    websiteUrl = vendorLocations;
  } else {
    singleLocation = vendorLocations;
  }
  const locationLabel = (
    <LocationLabel>
      <SvgIcon
        name={websiteUrl !== undefined ? 'Website' : 'LocationPin'}
        color={Colors.DarkGrey}
        size={15}
      />
      {singleLocation ? (
        singleLocation.address.addressDetails.city !== undefined ? (
          <Fragment>
            <div>{singleLocation.address.addressDetails.city}</div>
            <div>{`(${
              // For France, we show postal code, for other countries, the name of the country
              singleLocation.address.addressDetails.country === 'France' &&
              singleLocation.address.addressDetails.postalCode !== undefined
                ? singleLocation.address.addressDetails.postalCode.slice(0, 2)
                : singleLocation.address.addressDetails.country
            })`}</div>
          </Fragment>
        ) : (
          // Handle cases where the city is not defined, we display the country instead
          <div>{singleLocation.address.addressDetails.country}</div>
        )
      ) : websiteUrl !== undefined ? (
        <div>{cleanWebsiteUrl(websiteUrl)}</div>
      ) : (
        <div>{`${!Array.isArray(vendorLocations) ? 1 : vendorLocations.length} adresses`}</div>
      )}
    </LocationLabel>
  );

  let cseLabel = EmptyFragment;
  if (cse) {
    cseLabel = <CseLabel>Offre premium</CseLabel>;
  }

  const prices = bestStock.discount;
  const offer = bestStock.offer;

  const discountType = offer ? offer.discount.type : HoobiizDiscountType.Percent;
  const discount: HoobiizDiscount | undefined = !prices
    ? undefined
    : discountType === HoobiizDiscountType.Amount
      ? {
          type: HoobiizDiscountType.Amount,
          amount: substractCurrencyAmount(prices.price3, prices.price1),
        }
      : hoobiizPricesToDiscountPercent(prices);

  const priceComponent = promoCodeStock ? (
    <PromoCodeStock>{promoCodeStock.discount}</PromoCodeStock>
  ) : prices ? (
    <HoobiizPricesView prices={prices} prefix="Dès" justify="center" />
  ) : (
    // Out of stock
    EmptyFragment
  );

  const card = (
    <Card>
      <ImageAndLabels>
        <HoobiizMediaView media={media[0]} height={200} />
        {discount && !discountIsZero(discount) ? (
          <DiscountLabel>{discountToLongString(discount)}</DiscountLabel>
        ) : (
          EmptyFragment
        )}
        {locationLabel}
        {cseLabel}
        {!bestStock.hasFlexibleStocks ? (
          !bestStock.hasFixedStocks ? (
            promoCodeStock ? (
              <OfferIcon
                srcAndSizes={{srcData: customerPageIcon3, width: 25}}
                height={25}
                alt="Codes promos"
              />
            ) : (
              EmptyFragment
            )
          ) : (
            <OfferIcon
              srcAndSizes={{srcData: customerPageIcon1, width: 25}}
              height={25}
              alt="Réservation en ligne"
            />
          )
        ) : (
          <OfferIcon
            srcAndSizes={{srcData: customerPageIcon2, width: 25}}
            height={25}
            alt="Achat de e-billets"
          />
        )}
      </ImageAndLabels>
      <Info>
        <Type>{vendor?.name}</Type>
        <Title title={label}>{label}</Title>
        {priceComponent}
      </Info>
    </Card>
  );

  return clickable ? (
    <StyledNavLink to={getActivityUrl(activityAndSources)} key={id}>
      {card}
    </StyledNavLink>
  ) : (
    <Wrapper>{card}</Wrapper>
  );
};
HoobiizActivityCard.displayName = 'HoobiizActivityCard';

const StyledNavLink = styled(UnthemedNavLink)`
  display: block;
  width: 100%;
  max-width: 300px;
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 300px;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid ${Colors.LightGrey2};
  overflow: hidden;
  height: 100%;
`;

export const DummyCard = styled.div`
  width: 230px;
  height: 300px;
`;

const ImageAndLabels = styled.div`
  position: relative;
`;

const LocationLabel = styled.div`
  position: absolute;
  right: 0;
  top: 8px;

  display: flex;
  align-items: center;
  gap: 4px;

  background-color: #ffffffbb;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 5px;

  color: ${Colors.DarkGrey};
  font-size: 12px;
  font-weight: ${FontWeight.Bold700};
`;

const CseLabel = styled.div`
  position: absolute;
  right: 0;
  bottom: 30px;
  display: inline-block;
  color: white;
  background-color: ${Colors.Gold};
  padding: 5px;
  font-size: 12px;
  font-weight: ${FontWeight.Bold700};
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
`;

const DiscountLabel = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  display: inline-block;
  color: ${Colors.Green};
  background-color: ${Colors.LightGreen};
  padding: 5px;
  font-size: 12px;
  font-weight: ${FontWeight.Bold700};
  border-top-left-radius: 4px;
`;

const Info = styled.div`
  padding: 10px;
`;

const Type = styled.div`
  text-transform: uppercase;
  color: ${Colors.Grey};
  font-weight: ${FontWeight.Bold700};
  font-size: 14px;
  margin-bottom: 8px;
`;

const Title = styled.div`
  font-weight: ${FontWeight.Bold700};
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const OfferIcon = styled(Image)`
  position: absolute;
  left: 8px;
  top: 8px;
`;

const PromoCodeStock = styled.div`
  color: ${Colors.Green};
  font-size: 20px;
  font-weight: ${FontWeight.Bold700};
  text-align: center;
`;
